import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

// routes
import Products from './components/Products';
import Info from './components/Info';
import Login from './components/Login';
import Home from './components/Home';
import SearchMaint from './components/SearchMaint';
import Categories from './components/Categories';

export const Routes = () => {

    return (
        <Switch>
            <Route path="/" component={Login} exact />
            <Route path="/home" component={Home} />
            <Route path="/products" component={Products} />
            <Route path="/info/:page" component={Info} />
            <Route path="/search-maint" component={SearchMaint} />
            <Route path="/categories" component={Categories} />
        </Switch>
    );
}
export default Routes;
