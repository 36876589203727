import React from 'react';
import styled from 'styled-components';

import IconButton from './IconButton';
import SamModal from './SamModal';

const StatusContainer = styled.div<{ height: number }>`
    overflow-y: auto;
    height: ${props => props.height}px;
    width: 500px;
    p {
         font-size: 14px;
         margin: 4px;
    }
`
const ButtonRow = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`
interface StatusModalProps {
    height?: number;            // defaults to 100
    statusList: string[];
    onSubmit?: () => void;       // when given, "OK" button is shown
}
const StatusModal: React.FC<StatusModalProps> = (props) => {
    return (
        <SamModal component={StatusModalComponent as React.FC} componentProps={props} />
    )
}
const StatusModalComponent: React.FC<StatusModalProps> = (props) => {
    const containerRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

    const height = props.height ? props.height : 100;

    React.useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    })
    if (!props.statusList.length) {
        return null;
    }
    return (
        <StatusContainer ref={containerRef} height={height}>
            {props.statusList.map(text => {
                return (
                    <p key={text}>{text}</p>
                )
            })}
            {props.onSubmit &&
                <ButtonRow>
                    <IconButton id="submit" caption="OK" icon="fas fa-check" onClick={props.onSubmit} />
                </ButtonRow>
            }
        </StatusContainer>
    )
}
export default StatusModal;