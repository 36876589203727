import { MenuItemData, DivisionEnum } from './interfaces/lib-api-interfaces';
import { ThemesRecord } from './interfaces/lib-react-interfaces';

const menuItems = [
    new MenuItemData("Home", "/home"),
    new MenuItemData("Products", "/products"),
    new MenuItemData("Info pages", null, [
        new MenuItemData("About us", "/info/about"),
        new MenuItemData("Sources", "/info/sources"),
        new MenuItemData("Policies", "/info/policies")
    ]),
    new MenuItemData("Search maintenance", "/search-maint"),
    new MenuItemData("Categories & menu", "/categories"),
    new MenuItemData("Image tester", "/image-tester")
];

const isDashboard = true;
const domain = "dashboard.artthisdesign";       // change to dashboard.fernsgarden when live
const targetDomain = "artthisdesign.com";           // change to fernsgarden.com
const serverIp = "https://" + domain + ".com";
const logoUrl = "fg-dashboard-logo.jpg";
const division = DivisionEnum.fg;
const maxThumbnailSize = 500;
const carouselImageHeight = 500;

// themes is a standard object for passing to custom components
/* typical uses:
    backColor10 - input fields, forecolor when background is reversed
    backColor25 - buttons, logo, dropdown menus
    backColor50 - captions on grids (e.g., store near you)
*/
const sansFonts = "Roboto,Arial,Helvetica,sans-serif";
const themes: ThemesRecord = {
    serifFonts: "Volkhov,'Times New Roman',Times,George,Palatino,Garamond",
    sansFonts: sansFonts,
    h1FontFamily: sansFonts,
    foreColor: "#4a5929", // Pantone 574,
    backColor50: "#d3d584",  //  583 at 50%
    backColor25: "#e9ecc2",  //  583 at 25%
    backColor10: "#f6f8e6", // 583 at 10%
    navbarFont: sansFonts,
    defaultFonts: sansFonts
}

export default {
    isDashboard,
    menuItems,
    division,
    domain,
    targetDomain,
    maxThumbnailSize,
    carouselImageHeight,
    serverIp,
    logoUrl,
    themes
}

