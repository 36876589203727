const getHomePage = "/api/getFgHomePage";
const saveHomePage = "/api/fgSaveHomePage";
const fetchInfoText = "/api/fgGetInfoText/";    // + page;
const setInfoText = "/api/fgSetInfoText";
const dashboardLogin = "/api/dashboardLogin/fernsgarden";
const getDashboardProducts = "/api/fgGetDashboardProducts";   // + query;
const searchDashboardProducts = "/api/fgDashboardProductSearch";
const loadDashboardSku = "api/fgDashboardLoadSku/";     // + loadSku
const getDashboardCategories = "/api/fgGetDashboardCategories";
const search = "/api/wsSearch";
const createIndex = "/api/fgCreateIndex";
const getMenuData = "/api/fgGetDashboardMenuData";

const fetchData = "/api/loadTable/";     // + props.table + "/" + props.division;
const fetchSchema = "/api/getSchema/";       // + props.table;
const postData = "/api/updateTable";
const saveProduct = "/api/fgSaveProduct";
const saveCategories = "/api/fgSaveCategories";
const verifyUrl = "/api/fgVerifyUrl/";


const uploadImage = "/api/uploadImage";

export default {
    getHomePage,
    saveHomePage,
    fetchInfoText,
    setInfoText,
    dashboardLogin,
    getDashboardProducts,
    searchDashboardProducts,
    loadDashboardSku,
    getDashboardCategories,
    search,
    createIndex,
    getMenuData,
    fetchData,
    fetchSchema,
    postData,
    saveProduct,
    saveCategories,
    verifyUrl,

    uploadImage,

}