import React from 'react';
import styled from 'styled-components';

import { useFetchApi, usePostApi, genericApiError } from '../libs/useDataApiV2';
import { useTokens } from '../libs/SamState';
import IconButton from '../libs/IconButton';
import MasterPage from './MasterPage';
import { StyledErrorText, replaceAll } from '../libs/libSupport';
import SamTextEditor from '../libs/SamTextEditor';

import { FGInfoTextRecord, FGInfoTextEnum } from '../interfaces/fg-api-interfaces';

import api from '../api-url';

const MasterContainer = styled.div`
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
    p {
        text-align: left;
    }
`
const Buttons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    margin-bottom: 16px;
`
const TitleEditor = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    input {
        width: 300px;
    }
`
const TextEditor = styled.textarea`
    width: 100%;
    height: 450px;
    overflow-y: auto;
`
interface InfoProps {
    match: { params: { page: string } };
}
const Info: React.FC<InfoProps> = (props) => {
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [textRecord, setTextRecord] = React.useState<FGInfoTextRecord>({} as FGInfoTextRecord);
    const [fetchText, setFetchText] = React.useState<boolean>(false);
    const [urlParam, setUrlParam] = React.useState<string>();

    const { post } = usePostApi();
    const { getToken } = useTokens();
    const { fetch, isFetchLoading } = useFetchApi();

    const gotText = (result: FGInfoTextRecord) => {
        setTextRecord(result);
    }
    const textChanged = (text: string) => {
        setTextRecord({ ...textRecord, text });
    }
    const titleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextRecord({ ...textRecord, title: e.target.value });
    }
    React.useEffect(() => {
      //  console.log("urlParam=" + urlParam + ", params.page=" + props.match.params.page)
        if (fetchText || urlParam !== props.match.params.page) {
            setErrorMsg(undefined);
            setFetchText(false);
            setUrlParam(props.match.params.page);
            setTextRecord({} as FGInfoTextRecord);
            fetch(api.fetchInfoText + FGInfoTextEnum[props.match.params.page as keyof typeof FGInfoTextEnum], getToken()!.token, gotText, () => setErrorMsg(genericApiError));
        }
    });
    let title: string = '';
    if (textRecord.page === FGInfoTextEnum.about) {
        title = "About Us";
    } else if (textRecord.page === FGInfoTextEnum.sources) {
        title = "Where It's Made";
    } else if (textRecord.page === FGInfoTextEnum.policies) {
        title = "Shipping/Returns/Privacy";
    }

    const postSuccessful = () => {
        alert("Text saved");
    }
    const saveClicked = () => {
        setErrorMsg(undefined);
        post(api.setInfoText, textRecord, postSuccessful, () => setErrorMsg(genericApiError), getToken()!.token);
    }
    const cancelClicked = () => {
        setFetchText(true);
    }

    return (
        <MasterPage>
            {textRecord.text &&
                <MasterContainer>
                    <h1>{title}</h1>
                    {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
                    <Buttons>
                        <IconButton style={{ height: "40px" }} caption="Save all changes" icon="fas fa-check" onClick={saveClicked} />
                        <IconButton style={{ height: "40px", marginLeft: "16px" }} caption="Cancel all changes" icon="fas fa-ban" onClick={cancelClicked} />
                    </Buttons>
                    <TitleEditor>
                        <span>Page title:&nbsp;</span>
                        <input value={textRecord.title} onChange={titleChanged} />
                    </TitleEditor>
                    <p>To remove a link, right click on text</p>
                    <SamTextEditor id="infoText" html={textRecord.text} textHeight={500} onChange={textChanged} />
                </MasterContainer>
            }
        </MasterPage>
    )
}
export default Info;
//                    <SamTextEditor id={page} html={text!} textHeight={450} removePTags={true} handleExit={textSubmitted} />

/*
Welcome to Fern's Garden, a place to find beautiful, hand crafted, affordable items that are made in the U.S.A. or abroad in an ethical manner.
The artisans here that create and share their beautiful work earn a fair wage, and we support many American made companies.
For the creations that are made abroad, we strive to seek out makers that pay fair wages and give back to their communities.
You will notice that every product on this site explains where and how it is made. When you buy at Fern's Garden,
you can purchase something beautiful and feel good about helping those who really put their hands and heart into the work.

Once upon a time... a brother and a sister decided to combine their talents to
look for the American Dream. Musician Jacob Sokoloff had a talent for
creating musically tuned windchimes from steel pipes. His sister Fern had a
vision that people all over the world might enjoy these enchanting chimes.
From the garage where they started their company 35 years ago, <Link to="/jacobs-chimes">Jacob's Musical
Chimes</Link> grew to become ubiquitous in the world of perfectly tuned
chimes. The two were realizing the American Dream that an artisan with
nothing more than a workshop and a vision could find success in this great
land of opportunity.

Fern's 35 years of experience in the gift industry gave her a unique
perspective and insight to the challenges American craftspeople face in
this changing world. Fern was living the struggle, as well as witnessing the
hardships being suffered by her colleagues. She realized that even one great
store focused on American Artisans could make a difference.

And so Fern's Garden was born as a venue for the hard working American
artisan and a place to support Fair Traded, ethically made, imported art and
craft. Fern's Garden is indeed a glorious "garden" of gifts.

Fern opened her first store in Long Beach, California in 2004. In 2017 a second store opened in Berkeley, California.
Please join us in our mission to support  home grown talent, and not lose
the beautiful art and craft created in this country. Thank you for your
support, and for spreading the word. We thank you, and the artists thank you
even more!


                <h1>Fern's Garden Shipping, Returns and Privacy</h1>
                <p>
<b>Fern's Garden Shipping:</b> We ship via UPS ground or USPS to all U.S. addresses, including Alaska and Hawaii.
All orders of $25 or more to the same address include free shipping. We charge $7.00 to ship orders under $25.
We ship daily and most orders go out within two days. Please call us at 1-800-759-0871 or email info@fernsgarden.com
to ask about freight charges on orders that need to be shipped by air or out of the country.
</p>
<p>
<b>Fern's Garden Guarantee:</b> We take pride in knowing that you are completely satisfied with your Fern's Garden purchase or gift.
If you find that you are not satisfied for any reason, you are welcome to return your purchase for a full refund or credit
(please note that shipping and handling charges can only be refunded if the return is due to our error).
</p>
<p>
<b>Fern's Garden Return Policy:</b> If you need to return or exchange an item, please send it to us within 15 days of purchase.
Please make sure the product is in like-new condition with original packing materials.
For faster service, write "fernsgarden.com RETURN" on the outside of the package.
For your protection, please send your return prepaid via a shipping service that can be tracked, such as UPS, Fedex or USPS insured.
Please be sure to keep your shipping receipt. We cannot guarantee refunds for returns sent via a non-trackable method.
</p>
<p>
<b>Fern's Garden Privacy Policy:</b> We do not share your name, your email address, shipping address, phone number or any other personal information with anyone.
All credit card numbers are accepted over a secure, encrypted connection. If you have an account with us and ask us to store your credit card number
for future orders, we will store it in encrypted form that is protected from potential data breaches.
We do send email to our customers about special offers from time to time. When you create your account, you can choose to opt out of these communications.
If you ever receive a communication from us and wish to opt out of future communications, simply click the "unsubscribe" link at the bottom of the email.

*/