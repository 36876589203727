import React from 'react';

import MasterPage from './MasterPage';
import ProductsGrid from './ProductsGrid';

const Products: React.FC = () => {
    return (
        <MasterPage>
            <ProductsGrid />
        </MasterPage>
    )
}
export default Products;