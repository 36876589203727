import React from 'react';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';

import IconButton from './IconButton';

// import '../App.css';

import app from '../appData';
import { replaceAll } from './libSupport';

// this is dependent on "npm i @tinymce/tinymce-react"

/* ways to access edited value:
    1) default: component displays "Save" and "Cancel" buttons, caller passes handleExit callback which receives value
    2) do not pass handleExit callback, do pass onChange; onChange is passed text along with optional id field (useful if more than one editor on page)
*/
/* props: 
    id: passed back with onChange, needed for multiple editors on page
    html: string (html)
    removePTags: bool -- pass true to convert newlines to P tags on entry, and back to newlines on exit
    textWidth: int -- text area width
    textHeight: int -- to enable scrolling if necessary
    onChange(html, id)
    handleExit -- callback, edited text passed, or null to cancel
*/
const MasterContainer = styled.div<{ width: string; height: number; foreColor: string }>`
    width: ${props => props.width};
    height: ${props => props.height}px;
    color: ${props => props.foreColor};
    overflow-x: auto;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid;
    padding: 8px;
`
const ToolbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    width: 100%;
    max-width: 590px;
    margin: 4px auto 8px auto;
`
const ButtonContainer = styled.div`
    display: flex;
    margin-bottom: 8px;
    button {
        margin-left: 8px;
    }
`
const UrlInputContainer = styled.div`
    margin-bottom: 10px;
    display: flex;
`
interface TextEditorProps {
    id?: string;
    html: string;
    removePTags?: boolean;
    textWidth?: number;     // default to 100%
    textHeight?: number;
    onChange?: (html: string, id: string) => void;
    handleExit?: (html: string | null) => void;
}
// use the following when passing onChange rather than handleExit
export const convertNewlinesToParagraphTags = (text: string): string => {
    return ("<p>" + text.split('\n').join("</p><p>") + "</p>");
}
export const convertParagraphTagsToNewlines = (html: string): string => {
    if (html) {
        return html.split("</p><p>").join('\n').split("<p>").join('').split("</p>").join('');
    } else {
        return html;
    }
}
// when pasting text it seems spaces at end of line become hard
export const removeHardSpaces = (text: string): string => {
    return replaceAll(replaceAll(text, "&nbsp;", " "), "  ", " ");
}

const TextEditor: React.FC<TextEditorProps> = props => {
    const [editor, setEditor] = React.useState<any>();
    const [showURLInput, setShowURLInput] = React.useState<boolean>(false);
    const urlRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const height = props.textHeight ? props.textHeight : 300;

    React.useEffect(() => {
        if (showURLInput) {
            urlRef.current.focus();
        }
    }, [showURLInput]);

    const handleOk = () => {
        const html = editor!.getContent();
        props.handleExit!(props.removePTags ? convertParagraphTagsToNewlines(html) : html);
    }
    const handleCancel = () => {
        props.handleExit!(null);
    }

    const applyFormat = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const target = e.target as HTMLButtonElement;
        if (target.id === "LINK") {
            setShowURLInput(true);
        } else {
            editor.execCommand(target.id);
        }
    }

    const handleEditorChange = (content: string) => {
        if (props.onChange) {
            props.onChange(content, props.id ? props.id : '');
        }
    }
    const onLinkInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            confirmLinkDlg();
        }
    }
    const confirmLink = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {    // called from button; else called from pressing ENTER on input field
            e.preventDefault();
        }
        confirmLinkDlg();
    }
    const confirmLinkDlg = () => {
        if (!urlRef.current.value) {
            alert("Please enter a url");
        } else {
            editor.execCommand('mceInsertLink', false, urlRef.current.value);
            setShowURLInput(false);
        }
    }
    const urlInputKeyUp = (e: React.KeyboardEvent) => {
        if (e.key === "Escape") {
            setShowURLInput(false);
        }
    }

    interface InitObject {
        setup: (editor: any) => void;
        menubar: boolean;
        height: number;
        plugins: string[];
        toolbar: boolean;
    }
    const initObject: InitObject = {
        setup: (editor) => setEditor(editor),
        menubar: false,
        height: height,
        plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
        ],
        toolbar: false,
    }
    return (
        <MasterContainer width={props.textWidth ? props.textWidth + "px" : "100%"} height={height} foreColor={app.themes.foreColor}>
            {props.handleExit &&
                <ButtonContainer>
                    <button onClick={handleOk}>Save</button>
                    <button onClick={handleCancel}>Cancel</button>
                </ButtonContainer>}
            <Toolbar handleClick={applyFormat} />
            {showURLInput &&
                <React.Fragment>
                    <UrlInputContainer onKeyUp={urlInputKeyUp}>
                        <label>Type or paste the url:</label>
                        <input ref={urlRef} type="text" size={50} defaultValue='' onKeyDown={onLinkInputKeyDown} />
                        <button onClick={confirmLink}>Confirm</button>
                    </UrlInputContainer>
                </React.Fragment>
            }
            <Editor
                initialValue={props.removePTags ? convertNewlinesToParagraphTags(props.html) : props.html}
                init={initObject}
                onEditorChange={handleEditorChange}
            />
        </MasterContainer>
    );
}
interface ToolbarProps {
    handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
const Toolbar: React.FC<ToolbarProps> = (props) => {
    const backColor = app.themes.backColor10;
    const buttonStyle = { backgroundColor: backColor, padding: "0 8px 0 8px", margin: 0 }
    return (
        <ToolbarContainer>
            <IconButton id="bold" style={buttonStyle} icon="fa fa-bold" caption="Bold" onClick={props.handleClick} />
            <IconButton id="italic" style={buttonStyle} icon="fa fa-italic" caption="Italic" onClick={props.handleClick} />
            <IconButton id="underline" style={buttonStyle} icon="fa fa-underline" caption="Underline" onClick={props.handleClick} />
            <IconButton id="LINK" style={buttonStyle} icon="fa fa-link" caption="Link" onClick={props.handleClick} />
            <IconButton id="removeformat" style={buttonStyle} icon="fa fa-ban" caption="Clean" onClick={props.handleClick} />
        </ToolbarContainer>
    )
}

export default TextEditor;

