import deepcopy from 'deepcopy';
import mc from './money-calc';      // this is a file in interfaces; be sure to grab it

// interfaces shared with ReactFG and Node api
import { AddressRecord, CreditCardSummaryRecord, CreditCardRecord, MenuItemData, TokenRecord, ImageRecord } from './lib-api-interfaces';

export const shipOrderIdOffset = 500000;        // add this to raw order ID to make ship_order_id (stored in jmc_shiplog)

// verified means it is PayPal and no charges were processed
export enum SubmitOrderReturnStatus { verifiedAndCharged = 'C', verified = 'V', totalsMismatch = 'M', paymentDeclined = 'D' }
// following must agree with ShippableOrder.cs
// saved not used; billed=paid
export enum FGOrderStatus { entered = '0', saved = '1', billed = '2', packing = '3', shipped = '4', canceled = '5' }
export enum FGCustInfoOptions { addresses = 'A', orderInfo = 'O', creditCard = 'C', orderHistory = 'H', testOrderHistory = 'T' }
export enum FGInfoTextEnum { about = 'A', sources = 'S', policies = 'P', na = '' }
// following must agree with enum in CommonRewards.cs
export enum RewardsActivityEnum { sale = 'S', voucher = 'V', earnedEmailSent = 'E', closeToNextEmailSent = 'C', none = '?' }
export enum VoucherError { invalidCode = 201, notFound = 202, voucherUsed = 203 }

export const freeFreightRules = { minimum: 25, freight: 7 };
export const caTaxRate = 7.25;     // %

// following could be placed in its own file -- it is shared between api and client
// don't call this if order or shopping cart are null
// returns copies of order.totals and shopping cart array
export const calcOrderTotals = (order: FGOrderRecord, cart: FGOrddtlRecord[]): any => {
 //   export const calcOrderTotals = (order: FGOrderRecord, cart: FGOrddtlRecord[]): [TotalsRecord, FGOrddtlRecord[]] => {
        const totals: TotalsRecord = {
        inv_net: 0,
        discount: 0,
        sales_tax: 0,
        freight: 0,
        voucher_applied: 0,
        reward_applied: 0,
        nontaxable: 0,
        inv_tot: 0
    }
    const shoppingCart = deepcopy(cart) as FGOrddtlRecord[];

    if (order.voucher_tot) {
        totals.inv_net = totals.inv_tot = totals.nontaxable = order.voucher_tot;
        order.totals = totals;
        return [totals, shoppingCart];
    }

    shoppingCart.forEach(item => {
        const price = item.price;
        item.ext = mc.mul(item.qty, item.price!);
        totals.inv_net = mc.add(totals.inv_net, item.ext);
    });
    const rewardsBal = order.extras.rewards && order.extras.rewards.balance ? order.extras.rewards.balance : 0;
    if (rewardsBal >= 100) {
        totals.reward_applied = calcRewardApplied(rewardsBal);
    }
    totals.freight = totals.inv_net > freeFreightRules.minimum ? 0 : freeFreightRules.freight;
    if (order.extras.coupon && order.extras.coupon.discount) {
        totals.discount = mc.div(mc.mul(totals.inv_net, order.extras.coupon.discount), 100);
    }
    if (order.extras.voucher_applied && order.extras.voucher_applied.balance) {
        totals.voucher_applied = order.extras.voucher_applied.balance;
    }
    if (totals.inv_net - totals.discount < totals.reward_applied) {
        totals.reward_applied = mc.mul(Math.floor((totals.inv_net - totals.discount) / 10) * 10, 1);
    }
    const state = order.ship_address.state;
    if (state && state.toLowerCase() === 'ca') {
        totals.sales_tax = mc.div(mc.mul(caTaxRate, mc.subtract(mc.subtract(totals.inv_net, totals.discount), totals.nontaxable)), 100);
    }
    totals.inv_tot = mc.mul(totals.inv_net - totals.discount + totals.sales_tax + totals.freight - totals.voucher_applied - totals.reward_applied, 1);
    return [totals, shoppingCart];
}
export const calcRewardApplied = (rewardsBal: number): number => {
    return mc.mul(Math.floor(rewardsBal / 100) * 10, 1);
}

// following are stored in the sku field for fg5_product_related table
export enum FGHomePageProducts { featuredSku = 1, popularSku = 2, newSku = 3 }
export enum WhereItsMadeEnum {
    usa = 'U',
    fairTrade = 'F',
    europe = 'E',
    canada = 'C',
    latinAmerica = 'L',
    ethical = 'T',
    thailand = 'H',
    japan = 'J',
    australia = 'A',
    overseas = 'O',
    unknown = 'N',
}
export interface ApiContactRecord {
    name: string;
    email: string;
    phone: string;
    order_id: string;
    comment: string;
}
export interface SubmitOrderRecord {
    order: FGOrderRecord;
    shopping_cart: FGOrddtlRecord[],
    paymentMethod: PayMethod;
    cc: CreditCardRecord;
}
export interface SubmitOrderResult {
    status: SubmitOrderReturnStatus;
    verbiage?: string;              // cc result verbiage if cc declined
    order?: FGOrderRecord;        // the corrected order if issues were found
    shopping_cart?: FGOrddtlRecord[]; // the corrected cart if issues were found
    issues: string[];               // the issues that prevented the order from being submitted; zero length if no issues
    order_id?: number;               // valid if order was submitted successfully
}

export interface HomePageRecord {
    carousel: ImageRecord[];
    featured: FGBaseProductRecord[];
    popular: FGBaseProductRecord[];
    newItems: FGBaseProductRecord[];
    menuItems: MenuItemData[];          // not included in dashboard home page call
    [key: string]: any;
}
export enum PayMethod { cc = 'C', paypal = 'P' }
// following is an order as submitted via api from JS client
// the in-memory order record is same except address stored as classes; see useFGOrder for interfface
export interface FGOrderRecord {
    order_id: number;
    cust_id: number;        // int
    voucher_tot: number;    // if nonzero the cart is empty and the order is for a gift certificate
    bill_email: string;     // this is held separate in memory but integrated into bill_address before saving to DB
    bill_address: AddressRecord;
    ship_address: AddressRecord;
    extras: ExtrasRecord;
    totals: TotalsRecord;
}
export interface ExtrasRecord {
    gift_message?: string;
    gift_wrap?: boolean;
    comments?: string;
    coupon?: CouponRecord;
    voucher_applied?: VoucherRecord;
    rewards?: RewardsRecord;
    send_voucher_date?: Date;
    [key: string]: any
}
export interface CouponRecord {
    coupon: string;
    discount: number;       // int %
    free_item: FreeItemRecord;
}
export interface FreeItemRecord {
    sku_long: number;       // long
    caption: string;
    image: string;          // filename
}
export interface VoucherRecord {
    voucher_code: string;   // base-pin (includes dash)
    balance: number;        // money
}
export interface RewardsRecord {
    rewards_id: number;     // same as cust_id in fgdb_maillist
    balance: number;        // money
}
export interface TotalsRecord {
    inv_net: number;        // money
    nontaxable: number;        // money  (total of gift certificates purchased)
    discount: number;        // money 
    sales_tax: number;        // money 
    freight: number;        // money
    voucher_applied: number;        // money
    reward_applied: number;        // money
    inv_tot: number;        // money 
}
export interface ApiGetProductsRecord {
    count: number;
    categoryCaption: string;
    data: FGBaseProductRecord[] | FGProductRecord;
    // if count=1 data=FGProductRecord (suitable for full product page)
    // if count>1 data=FGBaseProductRecord[] (for thumbnail grid) and categoryCaption is passsed
    // if nothing found count=0
}
// passed into register as body
export interface ApiRegisterRecord {
    email: string;
    password: string;
    bill_address: AddressRecord;
    phone?: string;      // if rewards membership requested
}
export interface ApiLoginRecord {
    email: string;
    password: string;
}
// returned from login and register; along with token
export interface FGCustInfoRecord {
    cust_id: number;
    login_email: string;        // use in case default bill address doesn't have an email
    bill_addresses: AddressRecord[];
    ship_addresses: AddressRecord[];
    shopping_cart: FGOrddtlRecord[];
    credit_card: CreditCardSummaryRecord;
    rewards?: RewardsRecord;
    saved_order?: FGOrderRecord;
    order_history?: FGOrderHistoryRecord[];
    bill_address?: AddressRecord;       // returned with order_history
}
export interface FGLoginRecord {
    token: TokenRecord;
    custInfo: FGCustInfoRecord;
}
export interface FGInfoTextRecord {
    page: FGInfoTextEnum;
    title: string;
    text: string;
}
export interface FGOrderHistoryRecord {
    order_id: number;
    order_date: string;
    inv_net: number;
    ship_address: AddressRecord;
    track_no: string;
    details: FGOrddtlRecord[];
}

export interface GetDashboardProductsResult {
    data?: FGDashboardProductRecord[];    // returned if requested (using length param); Dashboard returned from fgLoadDashboardSku
    start?: number;      // start row #, returned if there is data
    totCount?: number;   // total no. of rows in table, returned if requested
}
export interface GetDashboardSkuResult {
    data: FGDashboardProductRecord | null;
}
// base record suitable for thumbnail grid
export interface FGBaseProductRecord {
    sku_long: number;   // long
    caption?: string;   // optional for related products
    price?: number;  // money; optional for related products
    whereItsMade?: WhereItsMadeEnum;
    oos?: boolean;      // not needed in detail (shopping cart) record
    url?: string;       // ditto
    image?: ImageRecord;      // same as images[0]; only filename and sizePct are required here
    images?: ImageRecord[];
    videos?: ImageRecord[];
    display_order?: number;
    ymal?: YmalRecord[];
}
export interface YmalRecord {
    sku_long: number;
    image: ImageRecord;
    caption: string;
    price: number;
}
export enum OrderHistoryStatusEnum { inStock = 'S', outOfStock = 'O', discontinued = 'D' }
export interface FGOrddtlRecord extends FGBaseProductRecord {
    qty: number;        // int
    ext?: number;        // money
    status?: OrderHistoryStatusEnum;    // for order history only
}
// used when showing single product
export interface FGProductRecord extends FGBaseProductRecord {
    bullet_points?: string;
    qoh: number;       // only needed on product "buy" page
}
export interface FGDashboardProductHomeSettings {
    isFeatured: boolean;
    isPopular: boolean;
    isNew: boolean;
    [key: string]: boolean
}
export interface FGDashboardProductRecord extends FGBaseProductRecord {
    isNew: boolean;
    vendor: string;
    vendor_id: number;
    // following are for dashboard product editor only
    keywords: string;
    bullet_points: string;
    ounces: number;
    categories: number[];      // list of categories this product belongs to
    homePageSettings: FGDashboardProductHomeSettings;
}

export interface FGCategoryTreeRecord {
    subcategories?: FGCategoryTreeRecord[];   // always undefined if subcategory
    categoryId: number;         // key to FGCategoryRecord for retrieving caption and url
    displayOrder?: number;       // from table; for inserting new categories
    // following are maintained by CategoryEditor
    isExpanded: boolean;
    isChecked: boolean;
    isIndented: boolean;        // in this one there is only one level of indentation
    parentId?: number;              // applies only to popup items
    subcategoryChecked?: boolean;    // applies only to main category items; used only inside category editor
}
export interface FGCategoryRecordProperty {
    caption: string;
    url: string;
    image_filename?: string;
}
export type FGCategoryRecord = Record<number, FGCategoryRecordProperty>
export interface FGSaveCategoriesApiRecord {
    categories: FGCategoryRecord;
    categoryTree: FGCategoryTreeRecord[];
}
