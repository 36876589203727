/********* CHANGE gridFormatter URL for production **************/
import React from 'react';
import styled from 'styled-components';

import SamGrid, { Cell } from '../libs/dashboard/SamGridV3';
import { useFetchApi, usePostApi, genericApiError } from '../libs/useDataApiV2';
import { useTokens } from '../libs/SamState';
import { formatImageUrl, getNearestParentId } from '../libs/libSupport';
import { skuLongToSku, skuToSkuLong } from '../libs/dashboard/dashboard-support';
import ProductEditor from './ProductEditor';

import { FormFieldRecord, FormFieldType, ImageSizeEnum } from '../interfaces/lib-api-interfaces';
import { GetDashboardProductsResult } from '../interfaces/fg-api-interfaces';

import api from '../api-url';

// MasterGridContainer holds horizontal ControlPanel and GridContainer below it
// GridContainer holds vertical StatusPanel and grid to right of it
const MasterContainer = styled.div`
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
`
const MasterGridContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`
const GridContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-left: 16px;
`
const ControlPanel = styled.div`
    margin: 0 auto 12px auto;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
`
const StatusPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 200px;
`
const StatusText = styled.p`
    margin-left: 32px;
    font-weight: bold;
`
const PagingButtons = styled.div<{ width: number }>`
    display: flex;
    margin-left: 32px;
    height: 16px;
    margin-bottom: 16px;
    font-size: 9px;
    width: ${props => props.width}px;
    cursor: pointer;
`
const PagingButton = styled.div<{ width: number, backColor: string }>`
    width: ${props => props.width}px;
    height: 28px;
    background-color: ${props => props.backColor};
    border: 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const ControlPanelText = styled.span`
    margin-left: 32px;
`
const ControlPanelInput = styled.input<{ width: number }>`
    width: ${props => props.width}px;
`
const ErrorText = styled.p`
    font-size: 18px;
    color: red;
    font-weight: bold;
    margin: 0 0 0 32px;
`
const ProductsGrid: React.FC = () => {
    /* following store request params for loading products from server:
        includeInactive=1 to include inactive, 0 active only (default to 0)
        startRow=row # relative to 0
        length= # of rows to load (0 for all)
        totCount=1 to return count of ALL rows (taking INCLUDE_INACTIVE), 0 to omit
        outOfStockOnly=1 to return out of stock only, 0 to use other params (overrides all params)
    */
    const [includeInactive, setIncludeInactive] = React.useState<boolean>(false);
    const [totCount, setTotCount] = React.useState<number>(0);      // set to 0 to force reread of length from server
    const [startRow, setStartRow] = React.useState<number>(0);
    const [chunkSize, setChunkSize] = React.useState<number>(-1);     // pass as "length"
    const [oosOnly, setOosOnly] = React.useState<boolean>(false);    // pass as "outOfStockOnly"

    const [dataSource, setDataSource] = React.useState<Record<string, any>[]>();

    //   const [newStartRow, setNewStartRow] = React.useState<number>(0);
    const [pageNum, setPageNum] = React.useState<number>(0);
    const [searchText, setSearchText] = React.useState<string>('');
    const [loadSearch, setLoadSearch] = React.useState<boolean>(false);
    const [editingProduct, setEditingProduct] = React.useState<number>(0);      // skuLong; displays product editor
    const [errorMsg, setErrorMsg] = React.useState<string>('');

    const skuRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const { fetch } = useFetchApi();
    const { post } = usePostApi();
    const { getToken } = useTokens();

    const pageCount = 20;
    const pagingBarWidth = 320;

    const setData = (result: GetDashboardProductsResult) => {
        if (result.totCount) {
            setTotCount(result.totCount);  // search api does not return row_count
            //       console.log("setting chunkSize to " + (Math.floor(result.totCount / pageCount) + 1))
            setChunkSize(Math.floor(result.totCount / pageCount) + 1);
        }
        if (result.data) {
            if (result.data!.length === 0) {
                setErrorMsg("No matching products found");
            }
            setDataSource(result.data);
            setStartRow(result.start as number);
        }
    }
    const searchSucceeded = (result: GetDashboardProductsResult) => {
        if (result.data!.length === 0) {
            setErrorMsg("No matching products found");
        }
        setData(result);
    }
    React.useEffect(() => {
        if (loadSearch) {
            setLoadSearch(false);
            if (searchText) {
                setErrorMsg('');
                post(api.searchDashboardProducts, { searchText, includeInactive }, searchSucceeded, error => setErrorMsg(error), getToken()!.token);
            }
        }
    }, [loadSearch]);

    React.useEffect(() => {
        console.log("useEffect: includeInactive=" + includeInactive + ", totCount=" + totCount + ", chunkSize=" + chunkSize + ", startRow=" + startRow + ", oosOnly=" + oosOnly)
        let query = "/?includeInactive=" + (includeInactive ? 1 : 0);
        if (totCount === 0) {
            query += "&totCount=1";
        }
        if (oosOnly) {
            query += "&oosOnly=1&length=0&startRow=0";
        } else {
            query += "&length=" + chunkSize;
            query += "&startRow=" + startRow;
        }
        setErrorMsg('');
        fetch(api.getDashboardProducts + query, getToken()!.token, setData, error => setErrorMsg(genericApiError));
    }, [startRow, includeInactive, oosOnly, totCount, chunkSize]);

    const colDefs: FormFieldRecord[] = [
        { name: "image", label: '', type: FormFieldType.image, fixedWidth: 50 },
        { name: "sku_long", label: "SKU" },
        { name: "caption", label: "Caption" },
        { name: "vendor", label: "Maker" },
        { name: "price", label: "Price", type: FormFieldType.money },
        { name: "oos", label: "OOS", type: FormFieldType.checkbox },
        { name: "editor", label: '', type: FormFieldType.icon, fixedWidth: 50, icon: "fas fa-ellipsis-h", iconFontSize: 22, cursor: "pointer" }
    ];

    const gridCellClicked = (cell: Cell): boolean => {
        if (cell.owningColumn === "editor") {
            setEditingProduct(cell.dataRow.sku_long);
        }
        return false;
    }
    const gridFormatter = (dataRow: Record<string, any>, name: string, value: any): string | null => {
        if (name === "image" && value) {
            return formatImageUrl(value as string, ImageSizeEnum.full);
        } else if (name === "sku_long") {
            return skuLongToSku(value as number);
        }
        return null;
    }
    const searchTextChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    }
    const searchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            setLoadSearch(true);
        }
    }
    const enterSkuKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            const target = e.target as HTMLInputElement;
            const skuLong = skuToSkuLong(target.value);
            if (!skuLong) {
                setErrorMsg("SKU is incorrectly formatted (should be digits and dashes only with no spaces)");
            } else {
                setEditingProduct(skuLong);
            }
        }
    }
    const clearSearch = () => {
        setSearchText('');
        setOosOnly(false);
    }
    const pagingClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const page = parseInt(getNearestParentId(e.target as HTMLElement).id);
        setStartRow(page * chunkSize!);
        setPageNum(page);
        clearSearch();
    }
    const includeInactiveCheckedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIncludeInactive(e.target.checked);
        clearSearch();
    }
    const showOosCheckedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOosOnly(e.target.checked);
        setSearchText('');
    }
    const renderPagingButtons = (): any[] => {
        const elements = [];
        for (let i = 0; i < pageCount; i++) {
            elements.push(<PagingButton key={i} id={i + ''} width={pagingBarWidth / pageCount} backColor={i === pageNum ? "gray" : "white"} onClick={pagingClick}>
                <span>{i + 1}</span>
            </PagingButton>)
        }
        return elements;
    }
    if (!dataSource) {
        return null;
    }
    const rowCount = (searchText || oosOnly) ? dataSource.length : totCount;
    return (
        <MasterContainer>
            {editingProduct ? (
                <ProductEditor skuLong={editingProduct} formSubmitted={() => setEditingProduct(0)} />
            ) : (
                <MasterGridContainer>
                    <h1>Products</h1>
                    <ControlPanel>
                        <ControlPanelText>Enter a SKU to edit or create:</ControlPanelText>
                        <ControlPanelInput ref={skuRef} width={100} placeholder="SKU" onKeyDown={enterSkuKeyDown} />
                        <ControlPanelText>Search for product on web:</ControlPanelText>
                        <ControlPanelInput width={180} value={searchText} placeholder="Search" onChange={searchTextChanged} onKeyDown={searchKeyDown} />
                        {errorMsg ? (
                            <ErrorText>{errorMsg}</ErrorText>
                        ) : (
                            <StatusText>Showing products {startRow + 1} - {startRow + dataSource.length} of {rowCount}</StatusText>
                        )}
                        <PagingButtons width={pagingBarWidth}>
                            {renderPagingButtons()}
                        </PagingButtons>
                    </ControlPanel>
                    <GridContainer>
                        <StatusPanel>
                            <div>
                                <input type="checkbox" style={{ marginBottom: "16px" }} checked={includeInactive} value={undefined} onChange={includeInactiveCheckedChanged} />
                                <span>Include inactive products</span>
                            </div>
                            <div>
                                <input type="checkbox" checked={oosOnly} value={undefined} onChange={showOosCheckedChanged} />
                                <span>Show out of stock only</span>
                            </div>
                        </StatusPanel>
                        <SamGrid columnDefs={colDefs} dataSource={dataSource!} marginLeft={8} gridStyles={{ rowHeight: 30 }} allowEditing={false} allowInsert={false} isControlled={true}
                            cellClicked={gridCellClicked} customFormatter={gridFormatter} />
                    </GridContainer>
                </MasterGridContainer>
            )}
        </MasterContainer>
    )
}

export default ProductsGrid;

/*
name: string;
label?: string;         // could be omitted on grid with no captions
type?: FormFieldType;       // default to text
initialValue?: any;
height?: number;
marginTop?: number;
validator?: ValidatorRecord;
comboSource?: FormComboFieldRecord[];
width?: number;
fixedWidth?: number;
button?: FormFieldButtonRecord;
onClick?: (id: string) => void;
hideField?: (values: Record<string, any>) => boolean;
visible?: boolean;              // default to true

// grid use only:
allowEditing?: boolean;
align?: FormFieldAlignType;     // default to left for text, right for numbers


dataSource: Record<string, any>[];
columnDefs: FormFieldRecord[];
id?: string;
allowEditing: boolean;
allowDelete?: boolean;
allowInsert?: boolean;
thumbNailMode?: boolean;
styles?: Record<string, any>;
gridStyles?: GridStylesRecord;
showCaptions?: boolean;             // default to true; overrides gridStyles
horizontalCellPadding?: number;     // default to 3
fixedCols?: number;
isControlled?: boolean;
customFormatter?: CustomFormatterCallback;
onChange?: (dataSource: Record<string, any>[], id: string | undefined) => void;
newRow?: (row: Record<string, any>) => void;     // called when a new row is added
contextMenuInvoked?: (cell: Cell) => ContextMenuItem[];
cellClicked?: (cell: Cell) => boolean;      // return false to disallow editing
*/