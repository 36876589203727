import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { formatImageUrl, getNearestParentId } from './libSupport';

import { MenuItemData } from '../interfaces/lib-api-interfaces';
import { VoidCallback } from '../interfaces/lib-react-interfaces';

import app from '../appData';

const smallNavFontWidth = 750;

/* props:
    id : string -- if there is more than one navbar this is required
    menuItems : MenuItemData[]
    height : overall height, defaults to 48
    fontSize : defaults to 24
    fontWeight : defaults to "bold" (could be "normal")
    backgroundImage OR backColor : defaults to themes.foreColor
    foreColor : defaults to themes.backColor10
    hoverForeColor : defaults to themes.foreColor
    hoverBackColor : defaults to themes.backColor10
    dropdownBackColor : defaults to themes.backColor25
    dropdownFontSize : defaults to fontSize
    isVisibleCallback : (optional) returns true if item should be shown
    itemClicked : callback for dropdown clicked if there is no handler function
        itemClicked({item: (main menu item caption), dropdown: (dropdown item caption or null if main item clicked) })

*/
// parse menu and popup indexes out of event.target.id
// is is coded "bhhvv" where hh is horizontal menu item (or button) and vv is dropdown item (or menu item), all with leading 0
//      e.g.: "File->Save File->Cancel" -- File->Save is b0000 and File->cancel is b0001
// returns { menuIndex, popupIndex }
const parseIndexes = (target: HTMLDivElement): { menuIndex: number; popupIndex: number, element: HTMLElement } => {
    target = getNearestParentId(target) as HTMLDivElement;
    return { element: target, menuIndex: parseInt(target.id.substring(1, 3)), popupIndex: target.id.length > 3 ? parseInt(target.id.substring(3, 5)) : -1 };
}
const AddLeadingZero = (index: number): string => {
    return index < 10 ? ("0" + index) : index + '';
}

const StyledMasterContainer = styled.div<{
    foreColor: string; backColor: string; hoverForeColor: string; hoverBackColor: string; bgImage: string | null;
    fontFamily: string | null; fontWeight: string; fontSize: number; height: number
}>`
    width: 100%;
    margin-top: -2px;
    color: ${props => props.foreColor};
    background-color: ${props => props.backColor};
    background-image: ${props => props.bgImage ? "url(" + props.bgImage + ")" : null}; 
    background-size: ${props => props.bgImage ? "cover" : null};
    font-family: ${props => props.fontFamily};
    font-weight: ${props => props.fontWeight};
    p:hover {
        background-color: ${props => props.hoverBackColor};
        color: ${props => props.hoverForeColor};
    }
    height: ${props => props.height}px;
    font-size: ${props => props.fontSize}px;
    @media screen and (max-width: ${smallNavFontWidth}px) {
        font-size: 12px;
        line-height: 14px;
    }
`
const StyledNavBar = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    position: relative; 
    height: 100%;
`
const StyledNavButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 8px;
  cursor: pointer;
`
const StyledNavButtonText = styled.p`
    text-align: center;
    margin: 0;
`
//------------- end dropdown menus ----------------

interface NavBarProps {
    menuItems: MenuItemData[];
    id?: string;     // must be unique if multiple navbars on a page
    height?: number;    // defaults to 48
    fontSize?: number;  // defaults to 24
    fontWeight?: string;    // defaults to "bold"
    dropdownFontSize?: number;  // defaults to fontSize
    backgroundImage?: string;   // defaults to null
    // colors are reversed in props
    backColor?: string;         // defaults to default foreColor
    foreColor?: string;         // defaults to backColor10
    // hover unreverses colors
    hoverForeColor?: string;    //defaults to default foreColor
    hoverBackColor?: string;    // defaults to backColor10
    dropdownBackColor?: string; // defaults to backColor25
    dropdownForeColor?: string; // defaults to default foreColor
    itemClicked?: (itemCaption: string, dropdownCaption: string | null) => void;
    isVisibleCallback?: (item: MenuItemData) => boolean;
}
interface PopupRecord {
    index: number;
    left: number;
    top: number
}
const NavBar: React.FC<NavBarProps> = (props) => {
    const [popup, setPopup] = React.useState<PopupRecord | null>(null);  //   { index, left, top }
    const history = useHistory();

    const dropdownButtonHeight = 24;
    const height = props.height ? props.height : 48;
    const fontFamily = app.themes.navbarFont ? app.themes.navbarFont : app.themes.defaultFonts;
    const fontSize = props.fontSize ? props.fontSize : 24;
    const fontWeight = props.fontWeight ? props.fontWeight : "bold";
    const dropdownFontSize = props.dropdownFontSize ? props.dropdownFontSize : fontSize;
    const backgroundImage = props.backgroundImage ? formatImageUrl(props.backgroundImage) : null;
    const backColor = props.backColor ? props.backColor : app.themes.foreColor;
    const foreColor = props.foreColor ? props.foreColor : app.themes.backColor10;
    const hoverForeColor = props.hoverForeColor ? props.hoverForeColor : app.themes.foreColor;
    const hoverBackColor = props.hoverBackColor ? props.hoverBackColor : app.themes.backColor10;
    const dropdownBackColor = props.dropdownBackColor ? props.dropdownBackColor : app.themes.backColor25;
    const dropdownForeColor = props.dropdownForeColor ? props.dropdownForeColor : app.themes.foreColor;

    // caller can create canvas and pass it for repeated calls
    const measureText = (text: string, font: string, passedCanvas?: HTMLCanvasElement): number => {
        //    console.log("measureText(" + text + ", " + font);
        // re-use canvas object for better performance
        const canvas = passedCanvas ? passedCanvas : document.createElement("canvas");
        const context = canvas.getContext("2d");
        context!.font = font;
        const metrics = context!.measureText(text);
        return metrics.width + 4;
    }
    const measureDropdownWidth = (popupItems: MenuItemData[]) => {
        let width = 0;
        popupItems.forEach(item => {
            const itemWidth = measureText(item.caption, dropdownFontSize + "px " + fontFamily) + 48;
            width = Math.max(width, itemWidth);
        });
        return width;
    }

    const handleMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
        const parsed = parseIndexes(e.target as HTMLDivElement);
        const index = parsed.menuIndex;
        if (props.menuItems[index].popupItems && props.menuItems[index].popupItems!.length && (!popup || popup.index !== index)) {
            const rect = parsed.element.getBoundingClientRect();
            const popupRect = { index, left: rect.x, top: rect.y + dropdownButtonHeight };
            const popupWidth = measureDropdownWidth(props.menuItems[index].popupItems!);
            if (popupRect.left > window.innerWidth - popupWidth) {
                popupRect.left = window.innerWidth - popupWidth;
            }
            if (popupRect.left < 0) {
                popupRect.left = 0;
            }
            setPopup(popupRect);
        }
    }
    const handleNavClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const indexes = parseIndexes(e.target as HTMLDivElement);
        navClicked(indexes.menuIndex, indexes.popupIndex);
    }
    const navClicked = (menuIndex: number, popupIndex: number) => {
        let href;
        if (popupIndex === -1) {
            // main menu item clicked
            href = props.menuItems[menuIndex].href;
        } else {
            // dropdown item clicked
            href = props.menuItems[menuIndex].popupItems![popupIndex].href;
        }
        if (popup) {
            setPopup(null);
        }
        if (!href && props.itemClicked) {
            props.itemClicked(
                props.menuItems[menuIndex].caption,
                popupIndex >= 0 ? props.menuItems[menuIndex].popupItems![popupIndex].caption : null
            );
        } else if (href) {
            if (href.startsWith("http")) {
                window.location.href = href;
            } else {
                history.push(href);
            }
        }
    }

    return (
        <StyledMasterContainer foreColor={foreColor} backColor={backColor} hoverForeColor={hoverForeColor} hoverBackColor={hoverBackColor} bgImage={backgroundImage}
            fontFamily={fontFamily} fontWeight={fontWeight} fontSize={fontSize} height={height} onMouseLeave={() => setPopup(null)}>
            <StyledNavBar>
                {props.menuItems.map((item, index) => {
                    return (!props.isVisibleCallback || props.isVisibleCallback(item)) &&
                        <StyledNavButton key={item.caption} id={"b" + AddLeadingZero(index)} onClick={handleNavClick} onMouseOver={handleMouseOver}>
                            {item.caption}
                        </StyledNavButton>
                })}
                {popup &&
                    <DropDown menuIndex={popup.index} dropDownItems={props.menuItems[popup.index].popupItems!} buttonHeight={dropdownButtonHeight}
                        backColor={dropdownBackColor} foreColor={dropdownForeColor} fontSize={dropdownFontSize}
                        left={popup.left - 16} top={popup.top} onClick={navClicked} closeDropDown={() => setPopup(null)} />}
            </StyledNavBar>
        </StyledMasterContainer>
    );
}

//------------- dropdown menus ----------------
const StyledDropDown = styled.div<{ fontSize: number; lineHeight: number; foreColor: string; backColor: string; left: number; top: number }>`
    z-index: 2000;
    position: fixed;
    background-color: ${props => props.backColor};
    color: ${props => props.foreColor};
    left: ${props => props.left}px;
    top: ${props => props.top}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font-size: ${props => props.fontSize}px;
    line-height: ${props => props.lineHeight}px;
    padding-bottom: 8px;
`
const StyledDropDownButton = styled.div<{ hoverForeColor: string; hoverBackColor: string }>`
    flex: 1;
    margin-left: 16px;
    margin-right: 16px;
    cursor: pointer;
    div:hover {
        color: ${props => props.hoverForeColor};
        background-color: ${props => props.hoverBackColor};
    }
`

interface DropDownProps {
    dropDownItems: MenuItemData[];
    left: number;
    top: number;
    menuIndex: number;
    fontSize: number;
    buttonHeight: number;
    foreColor: string;
    backColor: string;
    closeDropDown: VoidCallback;
    onClick?: (menuIndex: number, popupIndex: number) => void;
}
export const DropDown: React.FC<DropDownProps> = (props) => {
    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const indexes = parseIndexes(e.target as HTMLDivElement);
        props.onClick && props.onClick(indexes.menuIndex, indexes.popupIndex);
    }
    return (
        <StyledDropDown left={props.left} top={props.top} foreColor={props.foreColor} backColor={props.backColor} lineHeight={props.buttonHeight} fontSize={props.fontSize}
            onMouseLeave={e => props.closeDropDown()}>
            {props.dropDownItems.map((item, index) => {
                return (
                    <StyledDropDownButton key={item.caption} id={"b" + AddLeadingZero(props.menuIndex) + AddLeadingZero(index)}
                        hoverBackColor={props.foreColor} hoverForeColor={props.backColor} onClick={handleClick}>
                        <div>{item.caption}</div>
                    </StyledDropDownButton>
                )
            })}
        </StyledDropDown>
    );
}
//---------------------------------------------------------------
const menuItemHeight = 34;
const MasterContainer = styled.div`
    position: relative;
`
const MenuSlider = styled.div<{ zIndex: number }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 100vw;
    z-index: ${props => props.zIndex};
    width: 75vw;
    border: 1px solid;
    position: fixed;
    margin: 0;
    padding: 0;
`
const MenuContainer = styled.div<{ fontFamily: string; backColor: string; hoverForeColor: string; hoverBackColor: string }>`
    font: 18px ${props => props.fontFamily};
    background-color: ${props => props.backColor};
    div: hover {
        color: ${props => props.hoverForeColor};
        background-color: ${props => props.hoverBackColor};
    }
`
const MenuItem = styled.div<{ fontWeight: string; justify: string; foreColor: string; backColor: string }>`
    display: flex;
    justify-content: ${props => props.justify};     /* space-between or flex-start */
    align-items: center;
    font-weight: ${props => props.fontWeight};
    color: ${props => props.foreColor};
    background-color: ${props => props.backColor};
    cursor: pointer;
    padding-left: 12px;
    border-bottom: 1px solid;
    height: ${menuItemHeight}px;
`
interface SlidingNavBarProps extends NavBarProps {
    socialMedia: React.FC<{ justify?: string; backColor?: string; height?: number }>;
    vouchersLink: string;
}
export const SlidingNavBar: React.FC<SlidingNavBarProps> = (props) => {
    const [menuTop, setMenuTop] = React.useState<number>();
    const [menusActive, setMenusActive] = React.useState<boolean>(false);
    const [mainMenuOpen, setMainMenuOpen] = React.useState<boolean>(false);
    const [subMenuOpen, setSubMenuOpen] = React.useState<number>(-1);     // index of dropdown menu that is open
    const navbarRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const subMenuRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const history = useHistory();

    const backColor = props.backColor ? props.backColor : app.themes.foreColor;
    const foreColor = props.foreColor ? props.foreColor : app.themes.backColor10;
    const hoverBackColor = props.hoverForeColor ? props.hoverForeColor : app.themes.foreColor;
    const hoverForeColor = props.hoverBackColor ? props.hoverBackColor : app.themes.backColor10;
    const dropdownBackColor = props.dropdownBackColor ? props.dropdownBackColor : app.themes.backColor25;
    const dropdownForeColor = props.dropdownForeColor ? props.dropdownForeColor : app.themes.foreColor;

    React.useEffect(() => {
        const submenu = subMenuRef.current! as HTMLDivElement;
        console.log("useEffect: submenuOpen=" + subMenuOpen + ", mainMenuOpen=" + mainMenuOpen)
        if (submenu && subMenuOpen >= 0) {
            submenu.classList.add("slideIn");
            submenu.onanimationend = () => {
                submenu.classList.remove("slideIn");
                submenu.style.left = "25vw";
                navbarRef.current!.style.opacity = "0";
            };
        }
    }, [subMenuOpen]);

    React.useEffect(() => {
        if (mainMenuOpen) {
            const navbar = navbarRef.current as HTMLDivElement;
            console.log("navbar.style.left=" + navbar.style.left)
            navbar.style.opacity = "1";
            const className = mainMenuOpen ? "slideIn" : "slideOut";
            navbar.classList.add(className);
            navbar.onanimationend = () => {
                navbar.classList.remove(className);
                navbar.style.left = mainMenuOpen ? "25vw" : "100vw";
            };
        }
    }, [mainMenuOpen]);

    const showMenuClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = e.target as HTMLButtonElement;
        const top = target.getBoundingClientRect().y + 30;
        setMenuTop(top);
        setMainMenuOpen(!mainMenuOpen);
        setMenusActive(!menusActive);
    }
    const menuItemClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const indexes = parseIndexes(e.target as HTMLDivElement);
        if (props.menuItems[indexes.menuIndex].popupItems) {
            setSubMenuOpen(indexes.menuIndex);
        } else {
            setMainMenuOpen(false);
            setMenusActive(false);
            const href = props.menuItems[indexes.menuIndex].href as string;
            if (href.startsWith("http")) {
                window.location.href = href;
            } else {
                history.push(href);
            }
        }
    }
    const submenuItemClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        const indexes = parseIndexes(e.target as HTMLDivElement);
        const href = props.menuItems[indexes.menuIndex].popupItems![indexes.popupIndex].href as string;
        setMainMenuOpen(false);
        setSubMenuOpen(-1);
        setMenusActive(false);
        if (href.startsWith("http")) {
            window.location.href = href;
        } else {
            history.push(href);
        }
    }
    const closeSubMenuClicked = () => {
        navbarRef.current!.style.opacity = "1";
        const submenu = subMenuRef.current! as HTMLDivElement;
        submenu.classList.add("slideOut");
        submenu.onanimationend = () => {
            setSubMenuOpen(-1);
        };
    }
    return (
        <MasterContainer>
            <i style={{ fontSize: "24px" }} className="fas fa-bars" onClick={showMenuClicked} />
            {menusActive &&
                <React.Fragment>
                    <MenuSlider ref={navbarRef} zIndex={5000}>
                        <MenuContainer fontFamily={app.themes.sansFonts} backColor={backColor} hoverForeColor={hoverForeColor} hoverBackColor={hoverBackColor}>
                            <React.Fragment>
                                {props.menuItems.map((item, menuIndex) => {
                                    return (
                                        <React.Fragment key={item.caption}>
                                            <MenuItem fontWeight="bold" id={"b" + AddLeadingZero(menuIndex)} foreColor={foreColor} backColor={backColor} justify="space-between" onClick={menuItemClicked}>
                                                <span>{item.caption}</span>
                                                {item.popupItems && <i style={{ marginRight: "16px" }} className="fas fa-chevron-right"></i>}
                                            </MenuItem>
                                        </React.Fragment>
                                    )
                                })}
                                <MenuItem fontWeight="regular" foreColor={foreColor} backColor={backColor} justify="space-between" onClick={() => history.push(props.vouchersLink)}>
                                    <span>Gift Certificates...</span>
                                </MenuItem>
                            </React.Fragment>
                        </MenuContainer>
                        <props.socialMedia justify="center" backColor={backColor} height={menuItemHeight} />
                    </MenuSlider>
                    {subMenuOpen >= 0 &&
                        <MenuSlider ref={subMenuRef} zIndex={6000}>
                            <MenuContainer fontFamily={app.themes.sansFonts} backColor={backColor} hoverForeColor={hoverForeColor} hoverBackColor={hoverBackColor}>
                                <MenuItem fontWeight="bold" foreColor={foreColor} backColor={backColor} justify="flex-start" onClick={closeSubMenuClicked}>
                                    <i className="fas fa-chevron-left"></i>
                                    <span style={{ marginLeft: "16px" }}>{props.menuItems[subMenuOpen].caption}</span>
                                </MenuItem>
                                {props.menuItems[subMenuOpen].popupItems!.map((popup, popupIndex) => {
                                    return (
                                        <MenuItem key={popup.caption} id={"b" + AddLeadingZero(subMenuOpen) + AddLeadingZero(popupIndex)} foreColor={dropdownForeColor} backColor={dropdownBackColor}
                                            fontWeight="regular" justify="flex-start" onClick={submenuItemClicked}>
                                            {popup.caption}
                                        </MenuItem>
                                    )
                                })
                                }
                            </MenuContainer>
                        </MenuSlider>
                    }
                </React.Fragment>
            }
        </MasterContainer>
    )
}
export default NavBar;
