import React from 'react';
import styled from 'styled-components';
import deepEqual from 'deep-equal';
import deepcopy from 'deepcopy';

import SamTextEditor, { convertParagraphTagsToNewlines } from '../libs/SamTextEditor';
import IconButton from '../libs/IconButton';
import SamForm from '../libs/forms/SamFormV4';
import useFormMgr from '../libs/forms/useFormMgr';
import CategoryEditor, { extractSelections, integrateSelections } from '../libs/dashboard/CategoryEditor';
import Checkbox from '../libs/Checkbox';
import { useFetchApi, usePostApi, genericApiError, MultiApiResultRecord, MultiPostRecord } from '../libs/useDataApiV2';
import { useTokens } from '../libs/SamState';
import { skuLongToSku } from '../libs/dashboard/dashboard-support';
import { copyArrayWithoutDeleted, copyArrayWithUpdatedElement, StyledErrorText } from '../libs/libSupport';
import ImageListHandler, { anyImagesToUpload, CaptionOptionsEnum, UploadImages } from '../libs/dashboard/ImageListHandler';

import {
    FGDashboardProductHomeSettings, FGCategoryTreeRecord, FGCategoryRecord, FGDashboardProductRecord, FGSaveCategoriesApiRecord
} from '../interfaces/fg-api-interfaces';
import { VoidCallback } from '../interfaces/lib-react-interfaces';
import { FormFieldRecord, FormFieldType, GraphicDimensionType, ImageRecord } from '../interfaces/lib-api-interfaces';

import api from '../api-url';
import app from '../appData';

const ReadOnlyBar = styled.div`
    display: flex;
    height: 34px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-bottom: 16px;
`
const ReadOnlyLabel = styled.span`
    font-weight: bold;
    margin-left: 16px;
`
const ReadOnlyText = styled.span`
    margin-left: 4px;
`
const Buttons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
`
const BasicInfoForm = styled.div`
    padding-bottom: 8px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
const Editors = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: 1300px;
    max-width: 1400px;
`
const CategoryContainer = styled.div`
    width: 300px;
    margin-right: 8px;
`
const TextEditorContainer = styled.div`
    width: 400px;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    h3 {
        font-weight: bold;
        text-align: center;
        margin: 0;
    }
`
const PhotoEditorContainer = styled.div`
    margin-left: 16px;
    h3 {
        font-weight: bold;
        text-align: center;
        margin: 0;
    }
`
interface ProductEditorProps {
    skuLong: number;
    formSubmitted: VoidCallback;
}
/* read-only fields:
    sku_long: number;
    vendor: string;
    price: number;
  fields to edit:
    category: number[]  show as list with checkboxes; multiple categories allowed
    caption: string;    defaults to description in fgpos DB; simple field
    url: string;        simple field
    ounces: number (decimal);   optional; simple field
    keywords: string;       optional; simple field
    bullet_points: string;  text editor
    images: string[];       first image is main one
    videos: VideoRecord[];  optional
*/

const ProductEditor: React.FC<ProductEditorProps> = (props) => {
    const [homeSettings, setHomeSettings] = React.useState<FGDashboardProductHomeSettings>({} as FGDashboardProductHomeSettings);
    const [productData, setProductData] = React.useState<FGDashboardProductRecord>();   // product being edited
    const [categoryTree, setCategoryTree] = React.useState<FGCategoryTreeRecord[]>();
    const [categories, setCategories] = React.useState<FGCategoryRecord>();
    const [orgCategoryData, setOrgCategoryData] = React.useState<{ categoryTree: FGCategoryTreeRecord[]; categories: FGCategoryRecord }>();     // for dirty check
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [images, setImages] = React.useState<ImageRecord[]>();
    const [beginUploadImages, setBeginUploadImages] = React.useState(false);
    const [beginUploadData, setBeginUploadData] = React.useState(false);
    const [uploadingImages, setUploadingImages] = React.useState(false);

    const forms = useFormMgr();
    const textEditor = useTextEditor();
    const { multiPost } = usePostApi();
    const { multiFetch } = useFetchApi();
    const { getToken } = useTokens();

    // this depends on order of multiFetch call: sku is first, categories second
    const gotData = (results: MultiApiResultRecord[]) => {
        console.log("gotData:", results);
        if (!results[0]) {
            setErrorMsg("SKU does not exist; please add to retail products first");
        } else {
            const skuData = results[0].data as unknown as FGDashboardProductRecord;
            const categoryData = results[1].data as unknown as { categories: FGCategoryRecord; categoryTree: FGCategoryTreeRecord[] };
            setCategories(categoryData.categories);
            //            skuData.categories.sort((a, b) => a - b);
            setProductData(skuData);
            setImages(skuData.images);
            textEditor.setData(skuData.bullet_points);
            setHomeSettings(skuData.homePageSettings);
            const tree = integrateSelections(deepcopy(categoryData.categoryTree), skuData.categories);
            setCategoryTree(tree);
            //  console.log("setting orgCategoryData to:", { categoryTree: deepcopy(tree), categories: { ...categoryData.categories } })
            setOrgCategoryData({ categoryTree: deepcopy(tree), categories: deepcopy(categoryData.categories) });
        }
    }

    React.useEffect(() => {
        multiFetch([api.loadDashboardSku + props.skuLong, api.getDashboardCategories], getToken()!.token, gotData, error => setErrorMsg(genericApiError));
    }, []);
    React.useEffect(() => {
        if (beginUploadImages) {
            setBeginUploadImages(false);
            if (anyImagesToUpload(images!)) {
                setUploadingImages(true);
            } else {
                setBeginUploadData(true);
            }
        }
    }, [beginUploadImages]);
    const imageUploadComplete = () => {
        setUploadingImages(false);
        setBeginUploadData(true);
    }
    React.useEffect(() => {
        if (beginUploadData) {
            setBeginUploadData(false);
            const editedData = forms.getFormValues("product") as FGDashboardProductRecord;
            editedData.bullet_points = textEditor.getData();
            editedData.images = copyArrayWithoutDeleted(images!);
            console.log("editedData.images:", editedData.images)
            //    editedData.videos = videos;
            editedData.homePageSettings = homeSettings;
            const postProductData = loadDirtyValues(editedData, productData!);     // copy changes from productData to editedData
            console.log("dirty productData:", postProductData)
            const prodCategories = extractSelections(categoryTree!);
            if (!prodCategories.length) {
                alert("You must check at least one category");
                return;
            }
            //     console.log("productData.categories:", productData!.categories)
            if (!deepEqual(prodCategories, productData!.categories)) {
                postProductData.categories = prodCategories;
            }
            if (Object.keys(postProductData).length) {
                // something in the product data got changed
                postProductData.sku_long = productData!.sku_long;
                postProductData.isNew = productData!.isNew;
                if (productData!.isNew) {
                    postProductData.vendor = productData!.vendor;
                    postProductData.price = productData!.price;
                }
                //       console.log("postProductData:", postProductData);
            }
            const postCategoryData = {} as FGSaveCategoriesApiRecord;
            if (!deepEqual(categories, orgCategoryData!.categories)) {
                postCategoryData.categories = categories!;
            }
            if (!deepEqual(categoryTree, orgCategoryData!.categoryTree)) {
                postCategoryData.categoryTree = categoryTree!;
            }
            const postData: MultiPostRecord[] = [];
            if (Object.keys(postProductData).length) {
                postData.push({ url: api.saveProduct, data: postProductData });
            }
            if (Object.keys(postCategoryData).length) {
                postData.push({ url: api.saveCategories, data: postCategoryData });
            }
            if (postData.length) {
                multiPost(postData, postSuccess, () =>{ window.scrollTo(0, 0); setErrorMsg("Unable to upload product data to server") }, getToken()!.token);
            } else {
                alert("No changes found");
                props.formSubmitted();
            }
        }
    }, [beginUploadData]);

    const fields: FormFieldRecord[] = [
        { name: "caption", label: "Caption", width: 49 },
        { name: "url", label: "URL (leave blank for default)", width: 49 },
        { name: "keywords", label: "Keywords", width: 87 },
        { name: "ounces", label: "Ounces", width: 10, type: FormFieldType.int }
    ];

    const postSuccess = () => {
        alert("Product data uploaded to server");
        props.formSubmitted();
    }
    const saveClicked = () => {
        setBeginUploadImages(true);
    }
    // scan editedData and copy dirty fields
    const loadDirtyValues = (editedData: FGDashboardProductRecord, originalData: FGDashboardProductRecord): FGDashboardProductRecord => {
        const dirty = {} as Record<string, any>;
        for (const property in editedData) {
            if (!deepEqual(editedData[property as keyof FGDashboardProductRecord], originalData[property as keyof FGDashboardProductRecord])) {
                //         if (editedData[property as keyof FGDashboardProductRecord] !== originalData[property as keyof FGDashboardProductRecord]) {
                dirty[property] = editedData[property as keyof FGDashboardProductRecord];
            }
        }
        return dirty as FGDashboardProductRecord;
    }
    const homeSettingsCheckedChanged = (value: boolean, id: string) => {
        const settings = { ...homeSettings } as FGDashboardProductHomeSettings;
        settings[id] = value;
        setHomeSettings(settings);
    }
    const cancelClicked = () => {
        props.formSubmitted();
    }
    const handleCategoryChange = (tree: FGCategoryTreeRecord[] | null, cats: FGCategoryRecord | null) => {
        if (cats) {
            setCategories(cats);
        }
        if (tree) {
            setCategoryTree(tree);
        }
    }
    // called while uploading images to update filename
    const imageChanged = (updateIndex: number, newImage: ImageRecord) => {
        setImages(copyArrayWithUpdatedElement(images!, newImage, updateIndex));
    }

    let categoryList = '';
    let isNew = false;
    if (productData && categories) {
        isNew = !productData.url;
        productData.categories.forEach(categoryId => {
            if (!categories[categoryId]) {
                console.log("category " + categoryId + " not found");
            } else {
                categoryList += categories[categoryId].caption + ", ";
            }
        });
        categoryList = categoryList.slice(0, -2);
    }

    return (
        <React.Fragment>
            {errorMsg && <StyledErrorText>{errorMsg}</StyledErrorText>}
            {productData && categories && categoryTree && images &&
                <React.Fragment>
                    <h1>{(isNew ? "Adding" : "Editing") + " SKU " + skuLongToSku(productData.sku_long)}</h1>
                    {uploadingImages &&
                        <UploadImages images={images!} size={500} dimension={GraphicDimensionType.height} targetDomain={app.targetDomain} uploadImageApiUrl={api.uploadImage}
                        outputMagnified={true} imageChanged={imageChanged} uploadComplete={imageUploadComplete} />
                }
                    <ReadOnlyBar>
                        <ReadOnlyLabel>Description:</ReadOnlyLabel>
                        <ReadOnlyText>{productData.caption}</ReadOnlyText>
                        <ReadOnlyLabel>Vendor:</ReadOnlyLabel>
                        <ReadOnlyText>{productData.vendor}</ReadOnlyText>
                        <ReadOnlyLabel>Price:</ReadOnlyLabel>
                        <ReadOnlyText>${productData.price!.toFixed(2)}</ReadOnlyText>
                    </ReadOnlyBar>
                    <Buttons>
                        <IconButton style={{ height: "40px" }} caption="Save all changes" icon="fas fa-check" onClick={saveClicked} />
                        <IconButton style={{ height: "40px", marginLeft: "16px" }} caption="Cancel all changes" icon="fas fa-ban" onClick={cancelClicked} />
                    </Buttons>
                    <BasicInfoForm>
                        <SamForm id="product" forms={forms} fields={fields} initialValues={productData} />
                        <ReadOnlyBar>
                            <ReadOnlyLabel>Categories:</ReadOnlyLabel>
                            <ReadOnlyText>{categoryList}</ReadOnlyText>
                        </ReadOnlyBar>
                        <ReadOnlyBar>
                            <Checkbox id="isFeatured" checked={homeSettings.isFeatured} checkedChanged={homeSettingsCheckedChanged} caption='Home page "featured"' />
                            <Checkbox id="isPopular" marginLeft={20} checked={homeSettings.isPopular} checkedChanged={homeSettingsCheckedChanged} caption='Home page "popular"' />
                            <Checkbox id="isNew" marginLeft={20} checked={homeSettings.isNew} checkedChanged={homeSettingsCheckedChanged} caption='Home page "new"' />
                        </ReadOnlyBar>
                    </BasicInfoForm>
                    <Editors>
                        <CategoryContainer>
                            <CategoryEditor categories={categories} categoryTree={categoryTree!} allowSelections={true} onChange={handleCategoryChange} />
                        </CategoryContainer>
                        <TextEditorContainer>
                            <h3>Bullet Points</h3>
                            <span>Each paragraph is a bullet point.</span>
                            {textEditor.render()}
                        </TextEditorContainer>
                        <PhotoEditorContainer>
                            <h3>Product Images</h3>
                            <ImageListHandler id={"product"} images={images} direction="column" captions={CaptionOptionsEnum.disallow} allowAddNewImage={true}
                                onChange={(images: ImageRecord[]) => setImages(images)} />
                        </PhotoEditorContainer>
                    </Editors>
                </React.Fragment>
            }
        </React.Fragment>
    )
}
const useTextEditor = () => {
    const [bulletPoints, setBulletPoints] = React.useState<string>('');

    const setData = (bulletPoints: string) => {
        setBulletPoints(bulletPoints ? bulletPoints : '');
    }
    const getData = (): string => {
        return convertParagraphTagsToNewlines(bulletPoints);
    }
    const textChanged = (html: string) => {
        setBulletPoints(html);
    }
    const render = () => {
        if (!bulletPoints) {
            return null;
        }
        return (
            <SamTextEditor id="bulletPoints" html={bulletPoints} textHeight={500} removePTags={true} onChange={textChanged} />
        )
    }
    return {
        setData,
        getData,
        render
    }
}
export default ProductEditor;