import React from 'react';
import styled from 'styled-components';
import deepcopy from 'deepcopy';

import { getNearestParentId } from '../libSupport';
import SamContextMenu, { ContextMenuInfo, ContextMenuItem } from '../SamContextMenu';

import { FGCategoryTreeRecord, FGCategoryRecord, FGCategoryRecordProperty } from '../../interfaces/fg-api-interfaces';
import { FormFieldRecord } from '../../interfaces/lib-api-interfaces';
import { SamFormModal } from '../forms/SamFormV4';

const MasterContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    padding: 16px;
    padding-top: 8px;
    font-size: 14px;
    text-align: left;
    line-height: 30px;
    border: 1px solid;
    i {
        font-size: 24px;
    }
    p {
        font-weight: bold;
        text-align: center;
        margin: 0;
    }
`
const ListRow = styled.div<{ isIndented: boolean; backColor: string }>`
    margin-left: ${props => props.isIndented ? "24px" : 0};
    cursor: pointer;
    background-color: ${props => props.backColor};
`
const ButtonsRow = styled.div`
    display: flex;
    justify-content: center;
`
const HelpText = styled.span`
    font-size: 12px;
`
// helper function to create array of selected category ids
export const extractSelections = (categoryTree: FGCategoryTreeRecord[]): number[] => {
    const selected = [] as number[];
    categoryTree.forEach(item => {
        if (item.isChecked) {
            selected.push(item.categoryId);
        }
        if (item.subcategories) {
            item.subcategories.forEach(subcat => {
                if (subcat.isChecked) {
                    selected.push(subcat.categoryId);
                }
            });
        }
    });
    return selected.sort((a, b) => a - b);
}
export const integrateSelections = (categoryTree: FGCategoryTreeRecord[], selections: number[]): FGCategoryTreeRecord[] => {
    categoryTree.forEach(item => {
        if (selections.includes(item.categoryId)) {
            item.isChecked = true;
        }
        if (item.subcategories) {
            item.subcategories.forEach(subcat => {
                if (selections.includes(subcat.categoryId)) {
                    subcat.isChecked = true;
                    item.subcategoryChecked = true;
                }
            })
        }
    });
    return categoryTree;
}
interface TreeRowIndexes {
    catIndex: number;
    subcatIndex: number;
}
enum EditCategoryCmdEnum { insertMain, insertSub, editCaption, editUrl }
// this does not allow for fetch and post; parent must do those
// to show checkboxes and allow selecting, pass selectedCategories to setCategoryData
interface CategoryEditorProps {
    categoryTree: FGCategoryTreeRecord[];
    categories: FGCategoryRecord;
    allowSelections: boolean;
    onChange: (categoryTree: FGCategoryTreeRecord[] | null, categories: FGCategoryRecord | null) => void;
}
const CategoryEditor: React.FC<CategoryEditorProps> = (props) => {
    const [editCategoryInfo, setEditCategoryInfo] = React.useState<{ command: EditCategoryCmdEnum; treeLocation: TreeRowIndexes }>();
    const [showContextMenu, setShowContextMenu] = React.useState<{ x: number; y: number; treeLocation: TreeRowIndexes }>();

    const propagateSelected = (treeData: FGCategoryTreeRecord[]) => {
        treeData.forEach(item => {
            if (item.subcategories) {
                item.subcategoryChecked = item.subcategories.some(popup => popup.isChecked);
            }
        });
    }

    enum ItemTagEnum { minusSign = "far fa-minus-square", plusSign = "far fa-plus-square", checked = "far fa-check-square", unchecked = "far fa-square" }
    const getItemTag = (item: FGCategoryTreeRecord): ItemTagEnum => {
        let tag = ItemTagEnum.unchecked;
        if (item.isChecked) {
            tag = ItemTagEnum.checked;
        } else if (item.isExpanded) {
            tag = ItemTagEnum.minusSign;
        } else if (item.subcategories) {
            tag = ItemTagEnum.plusSign;
        }
        return tag;
    }
    const rowClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        const id = getNearestParentId(e.target as HTMLElement).id;
        const newTree = deepcopy(props.categoryTree);
        const row = getRow(newTree, parseIndexes(id));
        const tag = getItemTag(row);
        console.log("row:", row); console.log("tag=" + tag)
        if (tag.includes("plus")) {
            row.isExpanded = true;         // expand the group
        } else if (tag.includes("minus")) {
            row.isExpanded = false;        // collapse the group
        } else if (props.allowSelections && tag.includes("check")) {
            row.isChecked = false;
        } else if (props.allowSelections) {
            console.log("checking row id" + row.categoryId)
            row.isChecked = true;
        }
        propagateSelected(newTree);
        console.log("onChange:", newTree)
        props.onChange(newTree, null);
    }
    const editCategorySubmitted = (values: Record<string, any> | null) => {
        const row = getRow(props.categoryTree, editCategoryInfo!.treeLocation);
        if (values) {
            if (editCategoryInfo!.command === EditCategoryCmdEnum.editCaption) {
                updateCategoryCaptionAndUrl(row.categoryId, values.caption, null);
            } else if (editCategoryInfo!.command === EditCategoryCmdEnum.editUrl) {
                updateCategoryCaptionAndUrl(row.categoryId, null, values.url);
            } else {
                let url = values.url;
                if (!url) {
                    url = fixUrl(values.caption);
                }
                insertCategory(values.caption, url, editCategoryInfo!.command === EditCategoryCmdEnum.insertMain, editCategoryInfo!.treeLocation);
            }
        }
        setEditCategoryInfo(undefined);
    }
    const invalidUrl = "{}\\^[]`;/?:@&=+$,<>#%<>'\"";
    const fixUrl = (url: string): string => {
        let fixed = '';
        for (let i = 0; i < url.length; i++) {
            const code = url.charCodeAt(i);
            if (!(invalidUrl.includes(url[i]) || (code >= 0 && code <= 31) || code >= 127)) {
                if (url[i] === ' ') {
                    fixed += '-';
                } else {
                    fixed += url[i].toLowerCase();
                }
            }
        }
        return fixed;
    }
    // pass null for caption or url to keep existing
    const updateCategoryCaptionAndUrl = (categoryId: number, caption: string | null, url: string | null) => {
        const categories = { ...props.categories };
        if (caption !== null) {
            categories[categoryId].caption = caption;
        }
        if (url !== null) {
            categories[categoryId].url = url;
        }
     props.onChange(null, categories);
    }
    // isMain can be true only if a main category was clicked on
    // if isMain is false we are inserting under clicked main category or above clicked subcategory
    const insertCategory = (caption: string, url: string, isMain: boolean, location: TreeRowIndexes) => {
        //     console.log("insertCategory(" + caption + ", " + url + ", " + insertAt + ", " + isMain + ")")
        const newTree = deepcopy(props.categoryTree) as FGCategoryTreeRecord[];
        const newCategories = { ...props.categories };
        const newId = assignCategoryId(newTree);
        //    console.log("clicked row:", row)
        if (isMain) {
            const newRow: FGCategoryTreeRecord = { categoryId: newId, isExpanded: true, isChecked: false, isIndented: false };
            //       console.log("new row:", newRow)
            newTree.splice(location.catIndex, 0, newRow);
            newCategories[newId] = { caption, url };
            renumber(newTree);
        } else {
            //        console.log("parent row:", parentRow)
            newTree[location.catIndex].isExpanded = true;
            const newRow: FGCategoryTreeRecord = { categoryId: newId, isIndented: true, isChecked: props.allowSelections && true, isExpanded: false, parentId: location.catIndex };
            newCategories[newId] = { caption, url };
            if (newTree[location.catIndex].subcategories) {
                // inserting a subcategory where others exist; put it at the right place
                const index = location.subcatIndex + 1;      // this will insert after the clicked subcat; it will insert at top if main cat was clicked (subcatIndex would be -1)
                //        console.log("new row:", newRow)
                newTree[location.catIndex].subcategories!.splice(index, 0, newRow);
                renumber(newTree[location.catIndex].subcategories!);
            } else {
                // inserting the first subcategory in this category
                newRow.displayOrder = 100;
                //            console.log("new row:", newRow)
                newTree[location.catIndex].subcategories = [newRow];
            }
        }
        props.onChange(newTree, newCategories);
    }
    const renumber = (list: FGCategoryTreeRecord[]) => {
        list.forEach((item, index) => {
            item.displayOrder = (index + 1) * 100;
        });
    }
    const assignCategoryId = (categoryTree: FGCategoryTreeRecord[]): number => {
        let id = 0;
        categoryTree.forEach(item => {
            id = Math.max(item.categoryId, id);
            //    console.log(item.categoryId + "-" + item.caption)
            if (item.subcategories) {
                item.subcategories!.forEach(popup => {
                    id = Math.max(popup.categoryId, id);
                    //            console.log(popup.categoryId + "-" + popup.caption)
                });
            }
        });
        return id + 1;
    }

    //------- CONTEXT MENU ------------
    const contextMenuInvoked = (e: React.MouseEvent<HTMLSpanElement>) => {
        //     console.log("contextMenuInvoked: e.target=", e.target)
        const indexesId = getNearestParentId(e.target as HTMLElement).id;
        setShowContextMenu({ x: e.clientX, y: e.clientY, treeLocation: parseIndexes(indexesId) });
        e.stopPropagation();
        e.preventDefault();
    }
    // parse category/subcat indexes as given in id of div (e.g.: "1,2" -> [1,2])
    const parseIndexes = (indexes: string): TreeRowIndexes => {
        const parsed = indexes.split(',');
        return { catIndex: parseInt(parsed[0]), subcatIndex: parseInt(parsed[1]) };
    }
    const getRow = (tree: FGCategoryTreeRecord[], treeLocation: TreeRowIndexes): FGCategoryTreeRecord => {
        if (treeLocation.subcatIndex === -1) {
            return tree[treeLocation.catIndex];
        }
        return tree[treeLocation.catIndex].subcategories![treeLocation.subcatIndex];
    }
    // caption: row.caption, url: row.url, isMain: !row.isIndented
    // this is passed to the context menu component
    const buildContextMenu = (): ContextMenuInfo => {
        const treeRow = getRow(props.categoryTree, showContextMenu!.treeLocation);
        const menu: ContextMenuItem[] = [new ContextMenuItem("Edit caption for " + props.categories[treeRow.categoryId].caption, contextEditCategoryCaptionCmd, null)];
        if (treeRow.isIndented) {
            // clicked on a main category
            menu.push(new ContextMenuItem("Insert new main category here", contextInsertMainCmd, null));
            menu.push(new ContextMenuItem("Insert new subcategory under " + props.categories[treeRow.categoryId].caption, contextInsertSubCmd, null));
        } else {
            // clicked on a subcategory
            menu.push(new ContextMenuItem("Insert new subcategory here", contextInsertSubCmd, null));
        }
        menu.push(new ContextMenuItem("Edit url for " + props.categories[treeRow.categoryId].caption, contextEditCategoryUrlCmd, null));
        return new ContextMenuInfo({ x: showContextMenu!.x, y: showContextMenu!.y }, menu);
    }
    // return title, fields, initial values
    const buildEditCategoryFields = (): [string, FormFieldRecord[], Record<string, any>] => {
        const treeRow = getRow(props.categoryTree, editCategoryInfo!.treeLocation);
        if (editCategoryInfo!.command === EditCategoryCmdEnum.editCaption) {
            return ["Edit Category Caption", [{ name: "caption", label: "New caption" }], { caption: props.categories[treeRow.categoryId].caption }];
        }
        if (editCategoryInfo!.command === EditCategoryCmdEnum.editUrl) {
            console.log("buildEditCategoryFields: initial url=" + props.categories[treeRow.categoryId].url)
            return ["Edit URL for " + props.categories[treeRow.categoryId].caption, [{ name: "url", label: "New URL" }], { url: props.categories[treeRow.categoryId].url }];
        }
        if (editCategoryInfo!.command === EditCategoryCmdEnum.insertMain) {
            return ["Insert new main category", [{ name: "caption", label: "Caption" }, { name: "url", label: "URL" }], {}];
        }
        if (editCategoryInfo!.command === EditCategoryCmdEnum.insertSub) {
            return ["Insert new subcategory", [{ name: "caption", label: "Caption" }, { name: "url", label: "URL" }], {}];
        }
        throw "Invalid edit category command: " + editCategoryInfo!.command;
    }
    const closeContextMenu = () => {
        setShowContextMenu(undefined);
    }
    const contextEditCategoryCaptionCmd = () => {
        setEditCategoryInfo({ command: EditCategoryCmdEnum.editCaption, treeLocation: showContextMenu!.treeLocation });
        setShowContextMenu(undefined);
    }
    const contextEditCategoryUrlCmd = () => {
        setEditCategoryInfo({ command: EditCategoryCmdEnum.editUrl, treeLocation: showContextMenu!.treeLocation });
        setShowContextMenu(undefined);
    }
    const contextInsertMainCmd = () => {
        setEditCategoryInfo({ command: EditCategoryCmdEnum.insertMain, treeLocation: showContextMenu!.treeLocation });
        setShowContextMenu(undefined);
    }
    const contextInsertSubCmd = () => {
        setEditCategoryInfo({ command: EditCategoryCmdEnum.insertSub, treeLocation: showContextMenu!.treeLocation });
        setShowContextMenu(undefined);
    }
    //------- END CONTEXT MENU ------------

  //  console.log("props.categories:", props.categories); console.log("props.categoryTree:", props.categoryTree);
    const [editCategoryTitle, editCategoryFields, editCategoryInitialValues] = editCategoryInfo ? buildEditCategoryFields() : [];
    return (
        <MasterContainer>
            <p>Categories</p>
            {props.categoryTree.map((item, catIndex) => {
                const tag = getItemTag(item);
                //       console.log(item.caption)
                // if item is expanded, show minus sign; if item is collapsed but has subcategories, show plus sign; if item is collapsed but has no subcats, show checkbox
                return (
                    <React.Fragment key={props.categories[item.categoryId].caption}>
                        <ListRow id={catIndex + ",-1"} backColor={item.subcategoryChecked ? "yellow" : "white"} isIndented={item.isIndented}
                            onClick={rowClicked} onContextMenu={contextMenuInvoked}>
                            <i className={tag} />&nbsp;
                            {props.categories[item.categoryId].caption}
                        </ListRow>
                        <React.Fragment>
                            {tag.includes("minus") && item.subcategories &&
                                item.subcategories!.map((popupItem, subcatIndex) => {
                                    return (
                                        <ListRow key={props.categories[popupItem.categoryId].caption} id={catIndex + ',' + subcatIndex + ''} isIndented={popupItem.isIndented} backColor="white"
                                            onClick={rowClicked} onContextMenu={contextMenuInvoked}>
                                            {props.allowSelections &&
                                                <i style={{ marginRight: "4px" }} className={getItemTag(popupItem)} />
                                            }
                                            {props.categories[popupItem.categoryId].caption}
                                        </ListRow>
                                    )
                                })
                            }
                        </React.Fragment>
                    </React.Fragment>
                )
            })}
            <HelpText>Right click a category to add new category</HelpText>
            {editCategoryInfo &&
                <SamFormModal maxWidth={300} fields={editCategoryFields!} title={editCategoryTitle}
                    initialValues={editCategoryInitialValues}
                    createSubmitButton={true} createCancelButton={true} handleSubmit={editCategorySubmitted} />
            }
            {showContextMenu &&
                <SamContextMenu closePopup={closeContextMenu}
                    info={buildContextMenu()} />
            }
        </MasterContainer>
    );
}
export default CategoryEditor;

/*
                    <ModalInput width={300} fields={buildEditOrCreateCategoryFields()} allowCancel={true}
                        initialValues={{ caption: insertingOrEditingAt.isEditing ? insertingOrEditingAt.atCategoryCaption : '' }} onSubmit={newCategorySubmitted} />
*/