import React from 'react';
import styled from 'styled-components';

import IconButton from './IconButton';

import { IconButtonProps } from '../interfaces/lib-react-interfaces';

/*
to display a simple modal with text pass text prop and either submitCaption+Icon or submitButtons
to display any component as a modal pass component and optional componentProps
*/

const StyledModalParent = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 3000;
`
const StyledModal = styled.div<{ windowHeight?: number; backColor?: string }>`
    position: fixed;
    padding: 16px;
    background-color: ${props => props.backColor ? props.backColor : "white"};
    box-shadow: 3px 3px 5px 6px #ccc;
    top:50%; 
    left:50%; 
    transform: translate(-50%, -50%); 
    z-index: 3100;
    max-height: ${props => props.windowHeight}px;
    overflow-y: auto;
    font-size: 16px;
`
const ButtonRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
`
interface SamModalProps {
    // pass the first 2 to render a component as a modal
    component?: React.FC;
    componentProps?: Record<string, any>;   // pass with component
    // OR pass the following to render a modal with a text message
    modalId?: string;
    submitCaption?: string;     // defaults to "Close"; not shown if submitButtons passed
    submitIcon?: string;        // defaults to X
    backColor?: string;     // defaults to white
    onSubmit?: (modalId?: string, buttonId?: string) => void;   // pass this to show buttons
    text?: string;              // if this is not passed a component should be passed
    submitButtons?: IconButtonProps[];      // shown as row at bottom if passed; overrides submitCaption and icon; if onClick omitted click goes to onSubmit with button id
}
const SamModal: React.FC<SamModalProps> = (props) => {
    const onSubmit = () => {
        props.onSubmit!(props.modalId);
    }
    const NullComponent: React.FC = () => null;

    const defaultButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const id = (e.target as HTMLButtonElement).id;
        props.onSubmit!(props.modalId, id);
    }
    const componentProps = props.componentProps ? props.componentProps : {};

    return (
        <StyledModalParent>
            <StyledModal windowHeight={window.innerHeight} backColor={props.backColor}>
                {props.text ? (
                    <p>{props.text}</p>
                ) : (
                    props.component ? (<props.component {...componentProps} />) : (<NullComponent />)
                )}
                {(props.onSubmit) &&
                    <ButtonRow>
                        {props.submitButtons ? (
                            props.submitButtons.map(button => {
                                return (
                                    <IconButton {...button} onClick={button.onClick ? button.onClick : defaultButtonClick} key={button.id} />
                                )
                            })
                        ) : (
                            <IconButton caption={props.submitCaption ? props.submitCaption : "Close"} icon={props.submitIcon ? props.submitIcon : "fas fa-cancel"} onClick={onSubmit} />
                        )}
                    </ButtonRow>
                }
            </StyledModal>
        </StyledModalParent>
    )
}

export default SamModal;