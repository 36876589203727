import React from 'react';

// NOTE: This can only be used as a controlled input (caller tracks checked value)
interface CheckboxProps {
    id?: string;
    checked: boolean;
    caption: string;
    style?: Record<string, any>;
    marginLeft?: number;
    checkedChanged: (value: boolean, id: string) => void;
}
const Checkbox: React.FC<CheckboxProps> = (props) => {
    const checkedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        props.checkedChanged(target.checked, props.id ? props.id : '');
    }
    const style = props.style ? props.style : {};
    if (props.marginLeft) {
        style.marginLeft = props.marginLeft + "px";
    }
    return (
        <div style={props.style}>
            <input style={style} type="checkbox" value={''} checked={props.checked} onChange={checkedChanged} />
            <span>{props.caption}</span>
        </div>
    )
}
export default Checkbox;