import React from 'react';
import styled from 'styled-components';

import CategoryEditor from '../libs/dashboard/CategoryEditor';
import MasterPage from './MasterPage';
import { genericApiError, useFetchApi, usePostApi } from '../libs/useDataApiV2';
import { useTokens } from '../libs/SamState';

import { FGCategoryRecord, FGCategoryTreeRecord } from '../interfaces/fg-api-interfaces';

import api from '../api-url';
import IconButton from '../libs/IconButton';

const Buttons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-bottom: 16px;
`
const Categories: React.FC = () => {
    const [categories, setCategories] = React.useState<FGCategoryRecord>();       // must be in memory to edit/add products
    const [categoryTree, setCategoryTree] = React.useState<FGCategoryTreeRecord[]>();
    const [saveChanges, setSaveChanges] = React.useState(false);

    const { fetch } = useFetchApi();
    const { post } = usePostApi();
    const { getToken } = useTokens();

    const gotCategories = (result: { categories: FGCategoryRecord, categoryTree: FGCategoryTreeRecord[] }) => {
        setCategoryTree(result.categoryTree);
        setCategories(result.categories);
    }

    React.useEffect(() => {
        fetch(api.getDashboardCategories, getToken()!.token, gotCategories, error => alert(genericApiError));
    }, []);

    React.useEffect(() => {
        if (saveChanges) {
            setSaveChanges(false);
            post(api.saveCategories, { categories, categoryTree }, 
                () => {
                    alert("Changes have been uploaded to server");
                    window.location.href="/home";
                }, () => alert("Unable to upload changes to server"), 
            getToken()!.token);
        }
    }, [saveChanges]);

    const handleChange = (categoryTree: FGCategoryTreeRecord[] | null, categories: FGCategoryRecord | null) => {
        if (categories) {
            setCategories(categories);
        }
        if (categoryTree) {
            setCategoryTree(categoryTree);
        }
    }

    return (
        <MasterPage>
            <h2>Edit Categories and Main Menu</h2>
            {categories && categoryTree &&
                <React.Fragment>
                    <Buttons>
                        <IconButton style={{ height: "40px" }} caption="Save all changes" icon="fas fa-check" onClick={() => setSaveChanges(true)} />
                        <IconButton style={{ height: "40px", marginLeft: "16px" }} caption="Cancel all changes" icon="fas fa-ban" onClick={() => window.location.href="/home"} />
                    </Buttons>
                    <CategoryEditor categories={categories} categoryTree={categoryTree} allowSelections={false} onChange={handleChange} />
                </React.Fragment>
            }
        </MasterPage>
    )
}
export default Categories;