import React from 'react';
import styled from 'styled-components';

import { reorgArray } from './dashboard-support';
import { copyArrayWithUpdatedElement, formatImageUrl, getNearestParentId, ImageSizeEnum } from '../libSupport';
import { useTokens } from '../SamState';
import IconButton from '../IconButton';
import useFormMgr from '../forms/useFormMgr';
import SamForm from '../forms/SamFormV4';
import { usePostApi } from '../useDataApiV2';
import StatusModal from '../StatusModal';
import SamModal from '../SamModal';

import { FormFieldRecord, FormFieldType, GraphicDimensionType, ImageRecord, RowState } from '../../interfaces/lib-api-interfaces';
import { VerifyUrlResult } from '../../interfaces/lib-api-interfaces';
import SamTextEditor from '../SamTextEditor';
import RifmNumeric from '../forms/RifmNumeric';

const MasterContainer = styled.div<{ direction: string; fontSize: number }>`
    display: flex;
    justify-content: center;
    flex-direction: ${props => props.direction};
    font-size: ${props => props.fontSize}px;
    flex-wrap: wrap;
`
//---------- UPLOAD SUPPORT ----------------
// return true if there is at least one image to upload; if so use UploadImages
export const anyImagesToUpload = (images: ImageRecord[]): boolean => {
    return images.some(image => image.file);
}
// same as above but pushes the images onto an array
export const pushImagesToUpload = (images: ImageRecord[], toUpload: ImageRecord[]) => {
    images.forEach(image => {
        if (image.file) {
            toUpload.push({ ...image });
        }
    });
}

// the following displays a modal with upload progress, calls uploadComplete() when done
// only images with valid file property are uploaded
interface UploadImagesProps {
    images: ImageRecord[];
    size: number;
    dimension: GraphicDimensionType;
    targetDomain: string;
    graphicsSubfolder?: string;
    uploadImageApiUrl: string;
    outputMagnified?: boolean;      // if true the image will be uploaded as _f and _m versions (_m is double resolution)
    imageChanged: (index: number, newImage: ImageRecord) => void;   // required so corrected filename can be set
    uploadComplete: (status: boolean, message: string) => void;      // status is true on success
}

export const UploadImages: React.FC<UploadImagesProps> = (props) => {
    const [uploadedFiles, setUploadedFiles] = React.useState<string[]>([]);
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [index, setIndex] = React.useState<number>(-1);

    const { getToken } = useTokens();
    const { post } = usePostApi();

    React.useEffect(() => {
        setIndex(state => state + 1);
    }, []);

    const addUploading = (filename: string) => {
        const newFiles = uploadedFiles.map(file => file);
        newFiles.push("Uploading " + filename);
        setUploadedFiles(newFiles);
    }

    const imageUploaded = (filename: string) => {
        props.imageChanged(index, { ...props.images[index], filename });
        setIndex(state => state + 1);
    }

    React.useEffect(() => {
        if (index < props.images.length) {
            if (index >= 0) {
                const image = props.images[index!];
                if (image.file) {
                    addUploading(image.file.name);
                    const form = new FormData();
                    form.append("file", image.file);
                    form.append('size', props.size + '');
                    form.append('dimension', props.dimension);
                    form.append('domain', props.targetDomain);
                    form.append('outputMagnified', props.outputMagnified ? 'Y' : 'N');
                    if (props.graphicsSubfolder) {
                        form.append('graphics_subfolder', props.graphicsSubfolder);
                    }
              //      console.log("posting form:", { file: image.file, size: props.size, dimension: props.dimension, domain: props.targetDomain, graphics_subfolder: props.graphicsSubfolder });
                    post(props.uploadImageApiUrl, form, imageUploaded, (error: string) => setErrorMsg(error), getToken()!.token, true);
                }
                else {
                    setIndex(state => state + 1);
                }
            }
        } else {
            props.uploadComplete(true, "Uploaded " + uploadedFiles.length + " images");
        }
    }, [index]);

    React.useEffect(() => {
        if (errorMsg) {
            props.uploadComplete(false, errorMsg!);
        }
    }, [errorMsg]);

    return (
        <StatusModal statusList={uploadedFiles} />
    )
}
//---------------------------------------------------------------
enum ImageBoxFormatEnum { imageTop, imageLeft }
export enum CaptionOptionsEnum { allow = 'A', disallow = 'D', readOnly = 'R' }
export enum CaptionFormatEnum { html = 'H', plain = 'P' }

const formatImageSrc = (image: ImageRecord, graphicsSubfolder: string): string => {
    if (image.youtube_id) {
        return "https://img.youtube.com/vi/" + image.youtube_id + "/0.jpg";
    }
    return (image.file ? URL.createObjectURL(image.file) : formatImageUrl(image.filename!, graphicsSubfolder ? null : ImageSizeEnum.full, graphicsSubfolder ));
}

const ImageBox = styled.div<{ imageOnTop: boolean; width: number }>`
    display: flex;
    flex-direction: ${props => props.imageOnTop ? "column" : "row"};
    width: ${props => props.width}px;
    padding: 8px;
    margin: 8px;
    border: 1px solid;
`
// direction determines image/attribute placement:
//      "row" puts image above attributes (i.e., left to right)
//      "column" puts image to left of attributes (i.e., top to bottom)
interface ImageListHandlerProps {
    id: string;         // must be unique if more than one set of images on page
    images: ImageRecord[];
    imageBoxWidth?: number;      // defaults to 300px; if direction is column this is width of component; if direction is row this is width of each element
    maxImages?: number;         // defaults to unlimited
    captions?: CaptionOptionsEnum;   // defaults to disallowed
    captionFormat?: CaptionFormatEnum;  // html or plain; determines which editor to use; if captions allowed and not given, defaults to plain
    verifyUrlApiUrl?: string;       // if omitted, links will not be allowed
    direction: string;  // row or column
    graphicsSubfolder?: string;     // folder under ".../graphics"
    allowAddNewImage?: boolean;
    allowAddNewVideo?: boolean;
    hideAttributes?: boolean;       // defaults to false (caption and filename will be shown next to image)
    // following is height of each ROW or width of each COLUMN
    size?: number;       // size in dashboard editor; defaults to 100px; if direction is row image width is fixed to this; if direction is column image height is fixed to this

    // following 3 allow user to resize image and must all be given if resizing is allowed
    allowResize?: boolean;          // displaySize and displayDimension should be given; this allows user to change size stored with image record
    displaySize?: number;                       // this and displayDimension are for storing size and dimension in saved record for website rendering
    displayDimension?: GraphicDimensionType;

    style?: Record<string, any>;
    fontSize?: number;  // defaults to 13px
    onChange: (images: ImageRecord[]) => void;
}
const ImageListHandler: React.FC<ImageListHandlerProps> = (props) => {
    const fileInputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    const imageBoxWidth = props.imageBoxWidth ? props.imageBoxWidth : 300;
    const maxImages = props.maxImages ? props.maxImages : 0;        // 0 is unlimited
    const imageSize = props.size ? props.size : 100;
    const fontSize = props.fontSize ? props.fontSize : 13;
    const captions = props.captions ? props.captions : CaptionOptionsEnum.disallow;
    const captionFormat = props.captionFormat ? props.captionFormat : CaptionFormatEnum.plain;
    const allowAddNewImage = props.allowAddNewImage ? props.allowAddNewImage : false;

    let imageBoxFormat: ImageBoxFormatEnum;
    if (props.direction === "row") {
        imageBoxFormat = ImageBoxFormatEnum.imageTop;
    } else {
        imageBoxFormat = ImageBoxFormatEnum.imageLeft;
    }

    // move target to source and renumber
    const reorgImages = (source: number, target: number) => {
        props.onChange(reorgArray<ImageRecord>(props.images, source, target, true, true));
    }
    // insert new image from file system; target = -1 to add to end
    const insertImage = (newImage: ImageRecord, target: number) => {
        const newImages: ImageRecord[] = [];
        if (props.displayDimension) {
            newImage.dimension = props.displayDimension;
        }
        if (props.displaySize) {
            newImage.size = props.displaySize;
        }
        props.images.forEach((image, index) => {
            if (index === target) {
                newImages.push(newImage);
            }
            newImages.push({ ...image });
        });
        if (target === -1 || target === props.images.length) {
            newImages.push(newImage);
        }
        props.onChange(newImages);
    }

    const imageChanged = (newImage: ImageRecord, imageIndex: number) => {
        props.onChange(copyArrayWithUpdatedElement(props.images, newImage, imageIndex));
    }
    const imageDeleted = (imageIndex: number) => {
        const newImages: ImageRecord[] = [];
        props.images.forEach((image, index) => {
            const newImage = { ...image };
            if (index === imageIndex) {
                newImage.rowState = RowState.deleted;
            }
            newImages.push(newImage);
        });
        props.onChange(newImages);
    }

    const handleAddVideo = (image: ImageRecord | null) => {
        if (image) {
            image.rowState = RowState.added;
            if (props.displaySize) {
                image.size = props.displaySize;
            }
            const newImages = props.images.map(image => image);
            newImages.push(image);
            props.onChange(newImages);
        }
    }

    return (
        <MasterContainer direction={props.direction} fontSize={fontSize}>
            {props.images.map((image, index) => {
                return (
                    (image.rowState! & RowState.deleted) === 0 &&
                    (
                        props.hideAttributes ? (
                            <div key={image.filename} style={{ padding: "8px" }}>
                                <ILHImage id={props.id} index={index} image={image} size={imageSize} imageBoxFormat={imageBoxFormat} graphicsSubfolder={props.graphicsSubfolder}
                                    sizeConstrained={false}
                                    allowDragFromFileSystem={allowAddNewImage} reorgImages={reorgImages} insertImage={insertImage} />
                            </div>
                        ) : (
                            <ImageBox key={image.filename} width={imageBoxWidth} imageOnTop={imageBoxFormat === ImageBoxFormatEnum.imageTop}>
                                <ILHImage id={props.id} index={index} image={image} size={imageSize} imageBoxFormat={imageBoxFormat} graphicsSubfolder={props.graphicsSubfolder}
                                    sizeConstrained={true}
                                    allowDragFromFileSystem={allowAddNewImage} reorgImages={reorgImages} insertImage={insertImage} />
                                <ImageAttributesBox image={image} index={index} captions={captions} captionFormat={props.captionFormat} verifyUrlApiUrl={props.verifyUrlApiUrl}
                                    allowResize={props.allowResize} defaultDimension={props.displayDimension} defaultSize={props.displaySize}
                                    graphicsSubfolder={props.graphicsSubfolder}
                                    imageBoxFormat={imageBoxFormat} onChange={imageChanged} imageDeleted={imageDeleted} />
                            </ImageBox>
                        )
                    ))
            })}
            {props.allowAddNewImage &&
                <ILHImage id={props.id} index={props.images.length} image={{} as ImageRecord} size={imageSize} imageBoxFormat={imageBoxFormat} sizeConstrained={true}
                    allowDragFromFileSystem={true} reorgImages={reorgImages} insertImage={insertImage} />
            }
            {props.allowAddNewVideo &&
                <AddVideoBox size={imageSize} videoAdded={handleAddVideo} />
            }
        </MasterContainer>
    )

}
//-------------------------------------------------------------
const AddVideoBoxContainer = styled.div<{ size: number }>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border: 1px solid;
`
const AddVideoInput = styled.textarea`
    width: 90%;
    margin-left: 3%;
    height: 100px;
`
const AddVideoButtons = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 8px;
`
interface AddVideoBoxProps {
    size: number;
    videoAdded: (image: ImageRecord | null) => void;
}
const AddVideoBox: React.FC<AddVideoBoxProps> = (props) => {
    const videoInputRef = React.useRef<HTMLTextAreaElement>() as React.MutableRefObject<HTMLTextAreaElement>;

    const saveClicked = () => {
        //   console.log("got video: " + videoInputRef.current.value);
        if (!videoInputRef.current.value) {
            alert('Please enter the YouTube "share" or "embed" text');
            props.videoAdded(null);
        } else {
            const youtubeId = parseYoutubeId(videoInputRef.current.value);
            if (!youtubeId) {
                alert('Could not find YouTube video id. Embed text should include "youtu.be/nnnnnn" or "embed/nnnnn"');
                props.videoAdded(null);
            } else {
                videoInputRef.current.value = '';
                props.videoAdded({ youtube_id: youtubeId });
            }
        }
    }
    return (
        <AddVideoBoxContainer size={props.size}>
            <AddVideoInput ref={videoInputRef} placeholder='Find video on YouTube, click "share" or "embed", copy and paste the provided text' />
            <AddVideoButtons>
                <IconButton caption="OK" icon="fas fa-check" onClick={saveClicked} />
                <IconButton style={{ marginLeft: "16px" }} caption="Cancel" icon="fas fa-ban" onClick={() => props.videoAdded(null)} />
            </AddVideoButtons>
        </AddVideoBoxContainer>
    )
}
const parseYoutubeId = (text: string): string => {
    // text could be formatted as: "https://youtu.be/ts3s738ZkcQ" 
    // or '<iframe width="1038" height="584" src="https://www.youtube.com/embed/cKsTJtEClpw" frameborder="0" allow="accelerometer; ... etc. etc.'
    let posn = text.indexOf('embed/');
    if (posn >= 0) {
        posn += 6;
    } else {
        posn = text.indexOf('youtu.be/');
        if (posn >= 0) {
            posn += 9;
        }
    }
    //       console.log("posn=" + posn);
    if (posn === -1) {
        return '';
    }
    let id = '';
    for (let i = posn; i < text.length && text[i] !== ' ' && text[i] != '"'; i++) {
        id += text[i];
    }
    return id;
}
//-------------------------------------------------------------
const ImageAttributes = styled.div<{ marginLeft: number; marginTop: number; }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: ${props => props.marginLeft}px;    // for image on left
    margin-top: ${props => props.marginTop}px;      // for image on top
`
const TagText = styled.p`
    font-weight: bold;
    margin: 0;
`
const TextRow = styled.div<{ lineHeight: number }>`
    text-align: left;
    line-height: ${props => props.lineHeight}px;
    margin-bottom: 4px;
`
const LabelText = styled.span`
    font-style: italic;
    color: #666;
`
const ButtonsRow = styled.div`
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`
interface ImageAttributesBoxProps {
    image: ImageRecord;
    index: number;
    verifyUrlApiUrl?: string;       // if omitted links are not allowed
    imageBoxFormat: ImageBoxFormatEnum;     // determines where margin goes
    captions: CaptionOptionsEnum;
    captionFormat?: CaptionFormatEnum;
    allowResize?: boolean;
    // following 3 required if allowResize is true
    graphicsSubfolder?: string;
    defaultDimension?: GraphicDimensionType;    // defaults used if image is missing these properties
    defaultSize?: number;
    onChange: (image: ImageRecord, index: number) => void;
    imageDeleted: (index: number) => void;
}
const ImageAttributesBox: React.FC<ImageAttributesBoxProps> = (props) => {
    const [editingCaption, setEditingCaption] = React.useState<string>();
    const [editingLink, setEditingLink] = React.useState<string>();
    const [editingSize, setEditingSize] = React.useState(0);
    const [confirmingDelete, setConfirmingDelete] = React.useState<string>();
    const [verifyLink, setVerifyLink] = React.useState<string>();       // url to verify

    const { post } = usePostApi();
    const { getToken } = useTokens();

    const size = props.image.size ? props.image.size : (props.defaultSize ? props.defaultSize : undefined);

    React.useEffect(() => {
        console.log("useEffect: verifyLink=" + verifyLink)
        if (verifyLink) {
            setVerifyLink(undefined);
            post(props.verifyUrlApiUrl!, { url: verifyLink }, linkVerified, () => alert("Server error trying to verify link"), getToken()!.token);
        }
    }, [verifyLink])

    // api returns the link without https:// etc., or null if link is invalid
    const linkVerified = (url: string, status: number | undefined) => {
        const result = status as VerifyUrlResult;
        if (result === VerifyUrlResult.valid) {
            console.log("link verified: " + url)
            const newImage = { ...props.image };
            newImage.url = url;
            newImage.rowState! |= RowState.modified;
            props.onChange(newImage, props.index);
        } else {
            alert("Link url is invalid. Please fix.")
            setEditingLink(url);
        }
    }
    const attributeChanged = (id: string, text: string) => {
        setEditingCaption(undefined);
        setEditingLink(undefined);
        const newImage = { ...props.image };
        if (id === "caption") {
            newImage.caption = text;
            newImage.rowState! |= RowState.modified;
            props.onChange(newImage, props.index);
        } else {
            if (text.startsWith("/")) {
                text = text.slice(1);
            }
            if (text.endsWith("/")) {
                text = text.slice(0, -1);
            }
            setVerifyLink(text);
        }
    }
    const sizeChanged = (newSize: number) => {
        setEditingSize(0);
        if (newSize) {
            const newImage = { ...props.image };
            newImage.size = newSize;
            newImage.rowState! |= RowState.modified;
            props.onChange(newImage, props.index);
        }
    }
    const buttonClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
        const target = getNearestParentId(e.target as HTMLElement);
        if (target.id === "delete") {
            setConfirmingDelete(props.image.filename);
        } else if (target.id.endsWith("Caption")) {
            setEditingCaption(props.image.caption ? props.image.caption : '');
        } else if (target.id.endsWith("Size")) {
            setEditingSize(size!);
        } else {
            setEditingLink(props.image.url ? props.image.url : '');
        }
    }
    const confirmDeleteSubmitted = (confirmed: boolean) => {
        setConfirmingDelete(undefined);
        if (confirmed) {
            props.imageDeleted(props.index);
        }
    }

    const buttonStyle = { width: "26px", height: "19px", fontSize: "11px", display: "inline" };
    const defaultLineHeight = 21;       // need to leave space for delete/edit button
    let marginLeft: number;
    let marginTop: number;
    if (props.imageBoxFormat === ImageBoxFormatEnum.imageLeft) {
        marginLeft = 8;
        marginTop = 0;
    } else {
        marginLeft = 0;
        marginTop = 8;
    }

    return (
        <ImageAttributes marginLeft={marginLeft} marginTop={marginTop}>
            {props.image.tag && <TagText>{props.image.tag}</TagText>}
            {(props.captions === CaptionOptionsEnum.allow || props.captions === CaptionOptionsEnum.readOnly) &&
                <TextRow lineHeight={props.captions === CaptionOptionsEnum.allow ? defaultLineHeight : 18}>
                    <LabelText>Caption:&nbsp;</LabelText>
                    <span dangerouslySetInnerHTML={{ __html: props.image.caption ? props.image.caption : "(none)" }} />
                    {props.captions === CaptionOptionsEnum.allow &&
                        <IconButton style={{ ...buttonStyle, marginLeft: "4px" }} id="editCaption" caption='' icon="fas fa-edit" onClick={buttonClicked} />
                    }
                </TextRow>
            }
            {props.verifyUrlApiUrl &&
                <TextRow lineHeight={defaultLineHeight}>
                    <LabelText>Link:&nbsp;</LabelText>
                    <span>{props.image.url ? props.image.url : "(none)"}&nbsp;</span>
                    <IconButton style={buttonStyle} id="editLink" caption='' icon="fas fa-edit" onClick={buttonClicked} />
                </TextRow>
            }
            {size &&
                <TextRow lineHeight={defaultLineHeight}>
                    <LabelText>Size:&nbsp;</LabelText>
                    <span>{size + "px " + (props.image.dimension === GraphicDimensionType.height ? "high" : "wide")}</span>
                    {props.allowResize &&
                        <IconButton style={buttonStyle} id="editSize" caption='' icon="fas fa-edit" onClick={buttonClicked} />
                    }
                </TextRow>
            }
            <TextRow lineHeight={defaultLineHeight}>
                {props.image.filename ? (
                    <React.Fragment>
                        <LabelText>File:&nbsp;</LabelText>
                        <span>{props.image.filename}&nbsp;</span>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <LabelText>YouTube ID:&nbsp;</LabelText>
                        <span>{props.image.youtube_id}</span>
                    </React.Fragment>
                )}
                <IconButton style={buttonStyle} id="delete" caption='' icon="fa fa-trash" onClick={buttonClicked} />
            </TextRow>
            {editingCaption !== undefined && <EditAttributeDlg id="caption" text={editingCaption} label={"Enter new caption for " + props.image.filename}
                captionFormat={props.captionFormat}
                onSubmit={attributeChanged} onCancel={() => setEditingCaption(undefined)} />}
            {editingLink !== undefined && <EditAttributeDlg id="link" text={editingLink} label={"Enter new link for " + props.image.filename}
                onSubmit={attributeChanged} onCancel={() => setEditingLink(undefined)} />}
            {editingSize > 0 && <ImageSizer
                src={formatImageSrc(props.image, props.graphicsSubfolder!)}
                dimension={props.image.dimension ? props.image.dimension : props.defaultDimension!}
                size={props.image.size ? props.image.size : props.defaultSize!}
                handleExit={sizeChanged} />}
            {confirmingDelete && <ConfirmDeleteDlg filename={confirmingDelete} onSubmit={confirmDeleteSubmitted} />}
        </ImageAttributes>
    )
}
//-------------------------------------------------------------
interface EditAttributeDlgProps {
    text: string;
    label: string;
    id: string;     // "caption" or "link"
    captionFormat?: CaptionFormatEnum;
    onSubmit: (id: string, text: string) => void;
    onCancel: () => void;
}
const EditAttributeDlg: React.FC<EditAttributeDlgProps> = (props) => {
    return (
        <SamModal component={EditAttributeModal as React.FC} componentProps={props} />
    )
}
const EditAttributeModal: React.FC<EditAttributeDlgProps> = (props) => {
    const [html, setHtml] = React.useState<string>();

    const forms = useFormMgr();

    const isHtmlCaption = props.id === "caption" && props.captionFormat && props.captionFormat === CaptionFormatEnum.html;

    React.useEffect(() => {
        if (isHtmlCaption) {
            setHtml(props.text);
        }
    }, []);

    const formSubmitted = () => {
        const text = isHtmlCaption ? html : forms.getValue(props.id, "text");
        props.onSubmit(props.id, text);
    }

    const fields: FormFieldRecord[] = [
        { name: "text", type: FormFieldType.multiLine, inputHeight: 50, label: props.label, validator: { maxLength: 150 } }
    ];
    return (
        <React.Fragment>
            {isHtmlCaption ? (
                html &&
                <SamTextEditor id="infoText" html={props.text} textHeight={500} onChange={text => setHtml(text)} />
            ) : (
                <SamForm id={props.id} forms={forms} fields={fields} initialValues={{ text: props.text }} />
            )}
            <ButtonsRow>
                <IconButton style={{ height: "40px" }} caption="OK" icon="fas fa-check" onClick={formSubmitted} />
                <IconButton style={{ height: "40px", marginLeft: "16px" }} caption="Cancel" icon="fas fa-ban" onClick={() => props.onCancel()} />
            </ButtonsRow>
        </React.Fragment>
    )
}
//-------------------------------------------------------------
interface ConfirmDeleteDlgProps {
    filename: string;
    onSubmit: (confirmed: boolean) => void;
}
const ConfirmDeleteDlg: React.FC<ConfirmDeleteDlgProps> = (props) => {
    const handleSubmit = (modalId?: string, buttonId?: string) => {
        props.onSubmit(buttonId === "confirm");
    }
    const buttonStyle = { width: "60px" };
    return (
        <SamModal text={"Are you sure you want to remove " + props.filename + "?"} onSubmit={handleSubmit}
            submitButtons={[
                { style: buttonStyle, id: "confirm", caption: "Yes" },
                { style: { ...buttonStyle, marginLeft: "16px" }, id: "cancel", caption: "No" }
            ]} />
    )
}
//-------------------------------------------------------------
const ImageContainer = styled.div<{ width?: string; height?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.width}; 
    height: ${props => props.height};
`
const Image = styled.img<{ width: string; height: string }>`
    max-width: ${props => props.width};
    max-height: ${props => props.height};
    width: auto;
    height: auto;
    cursor: pointer;
`
const NewImage = styled.div<{ width?: number; height?: number }>`
    width: ${props => props.width ? props.width + "px" : "auto"};
    height: ${props => props.height ? props.height + "px" : "auto"};
    border: 1px #ccc solid;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4px;
`
interface ILHImageProps {
    id: string;
    index: number;
    image: ImageRecord;
    size: number;
    sizeConstrained: boolean;           // if true image is constrained to square of dimension size; else the prominent dimension is sized and other dimension is auto
    imageBoxFormat: ImageBoxFormatEnum;
    graphicsSubfolder?: string;             // folder under ".../graphics" (e.g. "carousel")
    allowDragFromFileSystem: boolean;       // product related editors can't allow this; use drag and drop for re-ordering only
    reorgImages: (source: number, target: number) => void;          // move target index to source index and renumber
    insertImage: (image: ImageRecord, index: number) => void;   // new image from file system; index=-1 to add to end
}
const ILHImage: React.FC<ILHImageProps> = (props) => {
    const fileInputRef = React.useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;

    //-------- DRAG AND DROP --------------
    const handleDragStart = (e: React.DragEvent<HTMLImageElement>) => {
        const id = getNearestParentId(e.target as HTMLImageElement).id;
        const format = props.id;
        console.log("setting data: " + format + ", " + id);
        e.dataTransfer.setData(format, id);
    }
    const handleDragOver = (e: React.DragEvent) => {
        console.log("handleDragOver: props.id=" + props.id)
        e.preventDefault();
        e.stopPropagation();
        let valid = false;
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
            if ((props.allowDragFromFileSystem && e.dataTransfer.items[i].type.startsWith("image/")) || e.dataTransfer.items[i].type === props.id) {
                valid = true;
            }
        }
        e.dataTransfer.dropEffect = valid ? "copy" : "none";
    }
    const handleDrop = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const target = parseInt(getNearestParentId(e.target as HTMLImageElement).id);
        if (e.dataTransfer.files.length) {
            // dropping from file system
            if (e.dataTransfer.files.length > 1) {
                alert("Please drag only one photo at a time");
            } else {
                props.insertImage({ filename: e.dataTransfer.files[0].name, file: e.dataTransfer.files[0], rowState: RowState.added }, target);
            }
        } else {
            props.reorgImages(parseInt(e.dataTransfer.getData(props.id)), target);
        }
    }
    //-------- END DRAG AND DROP --------------

    //-------- BROWSE FOR FILE ------------
    const browseClicked = (e: React.MouseEvent<HTMLElement>) => {
        fileInputRef.current.click();
    }
    const handleFileChosen = () => {
        //    console.log("got file: " + fileInputRef.current.files[0].name);
        const elem = fileInputRef.current;
        if (!isImageFile(elem.files![0])) {
            alert("File must be an image (.jpg .png .gif)");
        } else {
            props.insertImage({ filename: elem.files![0].name, file: elem.files![0], rowState: RowState.added }, -1);
        }
    }
    const isImageFile = (file: File): boolean => {
        const filename = file.name.toLowerCase();
        const posn = filename.lastIndexOf('.');
        const ext = filename.substring(posn + 1, filename.length);
        return (ext === "jpg" || ext === "png" || ext === "gif");
    }
    //-------- END BROWSE FOR FILE ------------

    let imageWidth = props.size + "px";
    let imageHeight = props.size + "px";
    if (!props.sizeConstrained) {
        if (props.imageBoxFormat === ImageBoxFormatEnum.imageLeft) {
            imageHeight = "auto";
        } else {
            imageWidth = "auto";
        }
    }
    let imageContainerWidth: string | undefined = undefined;
    let imageContainerHeight: string | undefined = undefined;
    if (props.sizeConstrained) {
        if (props.imageBoxFormat === ImageBoxFormatEnum.imageLeft) {
            imageContainerHeight = "100%";
            imageContainerWidth = props.size + "px";
        } else {
            imageContainerWidth = "100%";
            imageContainerHeight = props.size + "px";
        }
    }
    return (
        <React.Fragment>
            {props.image.filename || props.image.youtube_id ? (
                <ImageContainer width={imageContainerWidth} height={imageContainerHeight}>
                    <Image width={imageWidth} height={imageHeight} id={props.index + ''} src={formatImageSrc(props.image, props.graphicsSubfolder!)}
                        onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleDrop} />
                </ImageContainer>
            ) : (
                <NewImage id={props.index + ''} width={props.size} height={props.size} onDragOver={handleDragOver} onDrop={handleDrop} onClick={browseClicked}>
                    <p>Drop new image here or click to browse for file</p>
                </NewImage>
            )}
            <input style={{ display: "none" }} ref={fileInputRef} onChange={handleFileChosen} type="file" />
        </React.Fragment>
    )
}

//--------------------------------------------------------
const ImageSizerContainer = styled.div<{ width: number }>`
    width: ${props => props.width}px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    h3 {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin: 4px;
    }
`
const ButtonRow = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
`
const ImageDiv = styled.div`
    display: flex;
    width: 100;
    margin-left: auto;
    margin-right: auto;
`
const SizeImageContainer = styled.img`
    height: auto;
    width: 100%;
    border: 1px solid;
`
const NativeSizeText = styled.p`
    margin-top: 4px;
    margin-bottom: 4px;
`
const StyledHScrollbar = styled.div<{ height: number }>`
    height: ${props => props.height}px;
    background-color: lightgray;
    width: 100%;
    margin-bottom: 32px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    div {
        width: ${props => props.height}px;
        height: ${props => props.height}px;
        background-color: gray;
    }
`
const StatusContainer = styled.div`
    text-align: center;
    font-size: 16px;
`
const SizeEntryBox = styled.div`
    display: flex;
    justify-content: center;
    span {
        margin-top: 4px;
    }
`
interface ImageSizerProps {
    src: string;
    displayWidth?: number;    // defaults to 400
    size: number;
    dimension: GraphicDimensionType;
    handleExit: (size: number) => void;       // pass 0 if user canceled; size corresponds to passed in dimension     
}
const ImageSizer: React.FC<ImageSizerProps> = (props) => {
    return (
        <SamModal component={ImageSizerComponent as React.FC} componentProps={props} />
    )
}
const ImageSizerComponent: React.FC<ImageSizerProps> = (props) => {
    const [nativeWidth, setNativeWidth] = React.useState(0);
    const [nativeHeight, setNativeHeight] = React.useState(0);
    const [imageWidth, setImageWidth] = React.useState(0);

    const scrollButtonWidth = 24;
    const displayWidth = props.displayWidth ? props.displayWidth : 400;
    const imageRef = React.useRef<HTMLImageElement>() as React.MutableRefObject<HTMLImageElement>;

    const imageLoaded = () => {
        if (imageRef.current) {
            const imgHeight = imageRef.current.naturalHeight;
            const imgWidth = imageRef.current.naturalWidth;
            if (imgHeight !== nativeHeight) {
                setNativeHeight(imgHeight);
                setNativeWidth(imgWidth);
                // in case dimension is height we need to calclulate current user-set width from user-set height
                const userWidth = (props.dimension === GraphicDimensionType.width ? props.size : props.size * imgHeight / imgWidth);
                setImageWidth(Math.floor(userWidth));
            //    console.log({ size: props.size, dimension: props.dimension, imgWidth, imgHeight, userWidth, sliderX: displayWidth * userWidth / imgHeight })
            }
        }
    }
    const handleExit = () => {
        props.handleExit(props.dimension === GraphicDimensionType.width ? imageWidth : Math.floor(nativeHeight / nativeWidth * imageWidth));
    }
    const handleCancel = () => {
        props.handleExit(0);
    }

    return (
        <ImageSizerContainer width={displayWidth}>
            <h3>Size Image</h3>
            <ButtonRow>
                <IconButton onClick={handleExit} caption="Use this size" />
                <IconButton style={{ marginLeft: "8px" }} onClick={handleCancel} caption="Cancel" />
            </ButtonRow>
            <ImageDiv>
                <SizeImageContainer ref={imageRef} src={props.src} onLoad={imageLoaded} />
            </ImageDiv>
            <StatusContainer>
                <NativeSizeText>Native image size is {nativeWidth} wide x {nativeHeight} tall.</NativeSizeText>
                <NativeSizeText>Current size is {imageWidth} wide x {Math.floor(nativeHeight / nativeWidth * imageWidth)} tall</NativeSizeText>
                <NativeSizeText>(image is not shown actual size)</NativeSizeText>
                <SizeEntryBox>
                    <span>Enter new width:&nbsp;</span>
                    <RifmNumeric name="width" fieldType={FormFieldType.int} initialValue={imageWidth} width={80}
                        onChange={(value: string | number) => setImageWidth(value as number)} />
                </SizeEntryBox>
            </StatusContainer>
        </ImageSizerContainer>
    )
}

export default ImageListHandler;
