// general support and constants for library functions and components
import styled from 'styled-components';

import app from '../appData';
import { RowState } from '../interfaces/lib-api-interfaces';

// if size is passed it is inserted with underscore before file extension
// e.g.: insert("hello.jpg", "f") -> "hello_f.jpg"
export enum ImageSizeEnum { full = 'f', magnified = 'm' };
// dashboard uses targetDomain as root, otherwise use app.productGraphicsBaseUrl (PUBLIC folder if running locally)
// app.targetDomain = "laurainbocrystals.com" or "sam.bond" etc. (if this is dashboard the targetDomain is to the production site)
export const formatImageUrl = (filename: string, size?: ImageSizeEnum | null, subDirectory?: string) => {
    const domainPart = (window.location.hostname === "localhost" && !app.isDashboard ? process.env.PUBLIC_URL : ("https://" + app.targetDomain));
    const subFolder = subDirectory ? ("/" + subDirectory) : '';
    const base = domainPart + "/graphics" + subFolder;
    const filenamePart = (size && filename.length > 4) ? insert(filename, filename.length - 4, "_" + size) : filename;
    return base + "/" + filenamePart;
}

export const formatVideoUrl = (youtube_id: string) => {
    return "https://www.youtube.com/embed/" + youtube_id;
}
// take a youtube id (e.g.: "IV33kwHqIa0" and convert to link to image e.g.: "https://img.youtube.com/vi/IV33kwHqIa0/0.jpg")
export const formatYouTubeLink = (youtube_id: string) => {
    return "https://img.youtube.com/vi/" + youtube_id + "/0.jpg";
}

export const formatItemNo = (itemNo: string): string => {
    return itemNo.substring(0, 1) + "-" + itemNo.substring(1, 4);
}
// convert e.g. 1322400700 to "13224-7"
export const skuLongToFormattedMin = (skuLong: number): string => {
    const productId = Math.floor(skuLong / 100000);
    const spec1Id = Math.floor((skuLong - productId * 100000) / 100);
    const spec2Id = skuLong % 100;
    let formatted = productId + '';
    if (spec2Id) {
        formatted += ('-' + spec1Id + '-' + spec2Id);
    } else if (spec1Id) {
        formatted += ('-' + spec1Id);
    }
    return formatted;
}
export const formattedSkuToLong = (formatted: string): number => {
    const parts = formatted.split('-');
    let skuLong = parseInt(parts[0]) * 100000;
    if (parts.length > 1) {
        skuLong += (parseInt(parts[1]) * 100);
    }
    if (parts.length > 2) {
        skuLong += parseInt(parts[2]);
    }
    return skuLong;
}
export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const miniLayoutThreshhold = 900;       // when screen width is below this switch to "mini" layout with nav bar below logo
export const smallFontLayoutThreshhold = 1100;  // when screen width is below this use smaller font for menus

export const desktopWidth = 1200;      // desktop machine
export const iPadMinWidth = 702;
export const iPadMaxWidth = 768;
export const iPhoneWidth = 414;


export const sleep = (milliseconds: number) => {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}
export const StyledErrorText = styled.h4`
    text-align: center;
    width: 100%;
    color: red;
    font-size: 18px;
`
export const insert = (str: string, index: number, value: string) => {
    return str.substring(0, index) + value + str.substr(index);
}
export const replaceAll = (source: string, search: string, replace: string): string => {
    return source.split(search).join(replace);
}
export const deformat = (value: string): string => {
    return value.replace(/\W/g, '');
}
export const formatPhone = (value: string): string => {
    return value.substring(0, 3) + "-" + value.substring(3, 6) + "-" + value.substring(6, 10);
}
export const isAllNumeric = (value: string): boolean => {
    for (let i = 0; i < value.length; i++) {
        if (value.charAt(i) < '0' || value.charAt(i) > '9') {
            return false;
        }
    }
    return true;
}
export const getNearestParentId = (target: HTMLElement): HTMLElement => {
    while (!target.id) {
        target = target.parentElement as HTMLElement;
    }
    return target;
}
// caller can create canvas and pass it for repeated calls
export const measureText = (text: string, font: string, passedCanvas?: HTMLCanvasElement): number => {
    //    console.log("measureText(" + text + ", " + font);
    // re-use canvas object for better performance
    const canvas = passedCanvas ? passedCanvas : document.createElement("canvas");
    const context = canvas.getContext("2d");
    context!.font = font;
    const metrics = context!.measureText(text);
    return metrics.width + 4;
}
export function copyArrayWithUpdatedElement<T>(list: T[], newItem: T, updateIndex: number): T[] {
    const newList = list.map((item, index) => {
        if (index === updateIndex) {
            return newItem;
        }
        return item;
    });
    return newList;
}
export function copyArrayWithoutDeleted<T>(list: T[]): T[] {
    const newList: T[] = [];
    list.forEach(item => {
        if (((item as Record<string, any>).rowState & RowState.deleted) === 0) {
            newList.push(item);
        }
    });
    return newList;
}
