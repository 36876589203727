import { FormFieldRecord, GraphicDimensionType, ImageRecord } from './lib-api-interfaces';

export enum DashboardContentType { text = 't', textList = 'tl', textPair = 'tp', photo = 'p', record = 'r' }
export enum RowState { unchanged = 1, added = 2, modified = 4, deleted = 8 };

/* ContentType:
    text: "t" -- stored as string
    text-list: "tl" -- stored as array
    text-pair: "tp" -- stored as array of { label: string, text: string }[]
    photo: "p" -- stored as array of:
        { 
            filename: string, 
            caption: string (caption can be null)
            is_fixed_size: bool (if true size and dimension must be given)
            size: int (if is_fixed_size this is the size and dimension must be given; if not this is the default size and dimension is always 'w')
            dimension: string ('h' or 'w': if is_fixed_size this is the sizing dimension; if not this is ignored)
            alt: optional (if not supplied caption is used)
            ---following apply to videos only:---
            youtube_id: youtube id (e.g.: cKsTJtEClpw)
            size: int -- width of image (height = width/1.78)
        }
    record: "r" -- array of objects containing multiple fields as a data row
*/

// following for uploading image for storage
export interface GraphicUploadRecord {
    src: string;
    file?: File;
    youtube_id?: string;
    isFixedSize?: boolean;
    caption: string;
    captionOnly: boolean;

}
export interface SchemaRow {
    column_name: string,
    data_type: string,      // note, this is verbose type; must be converted to sqlTypes for use in ParameterRecord
    max_length: number,
    isPrimaryKey?: boolean,
    isNullable: boolean
}

// returned from getDashboardContent api call
export interface GetDashboardContentResultRecord {
    isDashboard: boolean;
    content: DashboardContentRecord[];
}
// record returned to web site is as follows: { tag1: content, tag2: content ... etc } where tagx is value of tag field and content is parsed content field
// the following are for the dashboard editors
export type DashboardContentFieldType = string | string[] | { label: string; text: string }[] | DashboardPhotosRecord | DashboardRecordRecord;   // shortcut
export interface DashboardContentRecord {
    domain: string;         // the website domain
    is_dashboard: boolean;  // true if this is not yet in production
    url: string;                // e.g. "home" or "press"
    content_type: DashboardContentType;  // determines the record layout per extensions below
    caption: string;            // title for dashboard editor
    tag: string;
    // in the following fields replace "...Content" with [tag] for the result returned to web sites
    // e.g.: tag="appList", content_type=(textListContent) returns { appList: ["one", "two", "three"] }
    // they are stored as JSON in the content field of dbo.content table
    content: DashboardContentFieldType;     // this is stored as JSON in dbo.content; parsed/stringified from/to DashboardContentFieldType by api
    /*
    textContent?: string;
    textListContent?: string[];
    textPairListContent?: { label: string; text: string }[];
    photoListContent?: DashboardPhotosRecord[];
    recordContent?: DashboardRecordRecord;
    */
}
// following for uploading image for processing
export interface GraphicProcessingRecord extends ImageRecord {
    // file is required
    outputMagnified?: boolean;
    field?: string;
}

// for dashboard content
export interface DashboardPhotosRecord extends DashboardImageDisplayRecord {
    images: ImageRecord[];
}
// following used when editing images, applies to all images in a list
export interface DashboardImageDisplayRecord {
    max_items: number | null;
    size: number;
    dimension: GraphicDimensionType;    // determines which dimension size applies to; other dimension is auto
    is_fixed_size: boolean;
    graphics_subfolder?: string;
    allow_videos: boolean;
    allow_captions: boolean;
    allow_filesystem: boolean;      // for choosing images; true to allow user to drag image from file system
}
export interface DashboardRecordRecord {
    record_defs: FormFieldRecord[];
    sort_column: string;
    records: Record<string, any>[];         // only this is returned to website; rest is for dashboard
}
