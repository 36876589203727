import React from 'react';
import styled from 'styled-components';

import ImageListHandler, { CaptionOptionsEnum } from '../libs/dashboard/ImageListHandler';

import { ImageRecord } from '../interfaces/lib-api-interfaces';

const RelatedContainer = styled.div`
    display: flex;
    flex-direction: column;
    h2 {
        margin: 4px;
    }
`
interface RelatedProductEditorProps {
    title: string;
    id: string;
    images: ImageRecord[];
    onChange: (images: ImageRecord[]) => void;
}
const RelatedProductEditor: React.FC<RelatedProductEditorProps> = (props) => {
    return (
        <RelatedContainer>
            <h2>{props.title}</h2>
            <ImageListHandler id={props.id} images={props.images} direction="column" captions={CaptionOptionsEnum.readOnly} allowAddNewImage={false} onChange={props.onChange} />
        </RelatedContainer>
    )
}

export default RelatedProductEditor;
/*
    id: string;         // must be unique if more than one set of images on page
    images: ImageRecord[];
    imageBoxWidth?: number;      // defaults to 300px; if direction is column this is width of component; if direction is row this is width of each element
    maxImages?: number;         // defaults to unlimited
    allowCaptions: boolean;
    verifyUrlApiUrl?: string;       // if omitted, links will not be allowed
    direction: string;  // row or column
    // following is height of each ROW or width of each COLUMN
    size?: number;       // defaults to 100px; if direction is row image width is fixed to this; if direction is column image height is fixed to this
    style?: Record<string, any>;
    fontSize?: number;  // defaults to 13px
    onChange: (images: ImageRecord[]) => void;
*/