import React from 'react';
import styled from 'styled-components';

import { useFetchApi, usePostApi, genericApiError } from '../libs/useDataApiV2';
import { useTokens } from '../libs/SamState';
import IconButton from '../libs/IconButton';
import MasterPage from './MasterPage';
import CarouselEditor from './CarouselEditor';
import RelatedProductEditor from './RelatedProductEditor';
import { anyImagesToUpload, UploadImages } from '../libs/dashboard/ImageListHandler';
import { copyArrayWithUpdatedElement, formattedSkuToLong, skuLongToFormattedMin, copyArrayWithoutDeleted } from '../libs/libSupport';

import { FGBaseProductRecord, HomePageRecord } from '../interfaces/fg-api-interfaces';
import { GraphicDimensionType, ImageRecord } from '../interfaces/lib-api-interfaces';
import { RowState } from '../interfaces/dashboard-api-interfaces';

import api from '../api-url';
import app from '../appData';
import { isDirtyArray } from '../libs/dashboard/dashboard-support';

const MasterContainer = styled.div`
    display: flex;
    max-width: 1300px;
    width: 100%;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
`
const TitleText = styled.p`
    font-size: 32px;
    font-weight: bold;
    margin: 16px;
`

const Buttons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
`
const Home: React.FC = () => {
    const [doFetch, setDoFetch] = React.useState(false);
    const [carouselImages, setCarouselImages] = React.useState<ImageRecord[]>();
    const [featuredImages, setFeaturedImages] = React.useState<ImageRecord[]>();
    const [popularImages, setPopularImages] = React.useState<ImageRecord[]>();
    const [newItemsImages, setNewItemsImages] = React.useState<ImageRecord[]>();
    const [beginUploadImages, setBeginUploadImages] = React.useState(false);
    const [beginUploadData, setBeginUploadData] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);

    const { fetch } = useFetchApi();
    const { post } = usePostApi();
    const { getToken } = useTokens();

    /*
        caption?: string;
    filename?: string;
    youtube_id?: string;
    url?: string;       // link
    tag?: string;       // displayed in bold (useful for SKU #)
    file?: File;            // valid if image has been dragged or chosen from local filesystem
    display_order?: number;
    size?: number;                      // overrides size in DashboardImageDisplayRecord
    dimension?: GraphicDimensionType;   // overrides dimension in DashboardImageDisplayRecord
    rowState?: RowState;    // for ImageListHandler; caller should pass this as unchanged
    size_pct?: number;      // percentage to display image in apps; used for small items in fernsgarden.com
    embed_text?: string;    // for editing videos in dashboard
*/
    // map related products to image array for editing in dashboard
    const mapRelatedImages = (products: FGBaseProductRecord[]): ImageRecord[] => {
        const images: ImageRecord[] = products.map((product) => {
            return (
                {
                    caption: product.caption, tag: skuLongToFormattedMin(product.sku_long), filename: product.image!.filename, display_order: product.display_order,
                    rowState: RowState.unchanged
                }
            );
        });
        return images;
    }
    // map edited image array to related product array for uploading to server
    // filter out deleted items
    const mapRelatedProducts = (images: ImageRecord[]): FGBaseProductRecord[] => {
        const products: FGBaseProductRecord[] = []; 
        images.forEach((image => {
            if ((image.rowState! & RowState.deleted) === 0) {
                products.push({ sku_long: formattedSkuToLong(image.tag!) });
            }
        }));
        return products;
    }
    const gotData = (result: HomePageRecord) => {
        console.log("home page:", result)
        setFeaturedImages(mapRelatedImages(result.featured));
        setPopularImages(mapRelatedImages(result.popular));
        setNewItemsImages(mapRelatedImages(result.newItems));
        setCarouselImages(result.carousel);
    }
    React.useEffect(() => {
        setDoFetch(true);
    }, []);
    React.useEffect(() => {
        if (doFetch) {
            setDoFetch(false);
            fetch(api.getHomePage, getToken()!.token, gotData, () => alert(genericApiError));
        }
    }, [doFetch]);

    React.useEffect(() => {
        if (beginUploadImages) {
            setBeginUploadImages(false);
            if (anyImagesToUpload(carouselImages!)) {
                setUploading(true);
            } else {
                setBeginUploadData(true);
            }
        }

    }, [beginUploadImages]);

    const imageUpdated = (updateIndex: number, newImage: ImageRecord) => {
        setCarouselImages(copyArrayWithUpdatedElement(carouselImages!, newImage, updateIndex));
    }
    const loadIfDirty = (images: ImageRecord[], field: string, data: HomePageRecord) => {
        if (isDirtyArray(images)) {
            data[field] = mapRelatedProducts(images);
        }
    }
    React.useEffect(() => {
        if (beginUploadData) {
            setBeginUploadData(false);
            const data = {} as HomePageRecord;
            loadIfDirty(featuredImages!, "featured", data);
            loadIfDirty(popularImages!, "popular", data);
            loadIfDirty(newItemsImages!, "newItems", data);
            if (isDirtyArray(carouselImages!)) {
                data.carousel = copyArrayWithoutDeleted(carouselImages!);
            }
            if (Object.keys(data).length) {
                post(api.saveHomePage, data, dataSaved, () => alert(genericApiError), getToken()!.token);
            }
        }
    }, [beginUploadData]);

    const imageUploadComplete = (status: boolean, message: string) => {
        setUploading(false);
        setBeginUploadData(true);
    }

    const dataSaved = () => {
        alert("Home page saved to server");
        setDoFetch(true);
    }
    const saveClicked = () => {
        setBeginUploadImages(true);
    }
    const carouselChanged = (newImages: ImageRecord[]) => {
        setCarouselImages(newImages);
    }
    const cancelClicked = () => {
        setDoFetch(true);
    }
    return (
        <MasterPage>
            {carouselImages && featuredImages && popularImages && newItemsImages &&
                <React.Fragment>
                    <TitleText>Home Page Products</TitleText>
                    <Buttons>
                        <IconButton style={{ height: "40px" }} caption="Save all changes" icon="fas fa-check" onClick={saveClicked} />
                        <IconButton style={{ height: "40px", marginLeft: "16px" }} caption="Cancel all changes" icon="fas fa-ban" onClick={cancelClicked} />
                    </Buttons>
                    {uploading &&
                        <UploadImages images={carouselImages!} size={500} dimension={GraphicDimensionType.height} targetDomain={app.targetDomain} uploadImageApiUrl={api.uploadImage}
                            imageChanged={imageUpdated} uploadComplete={imageUploadComplete} />
                    }
                    <CarouselEditor images={carouselImages!} onChange={carouselChanged} />
                    <p>To change order, drag and drop images. To add products, choose "Products" from the main menu and edit the products to be added.</p>
                    <MasterContainer>
                        <RelatedProductEditor title="Featured Products" id="featured" images={featuredImages} onChange={(images: ImageRecord[]) => setFeaturedImages(images)} />
                        <RelatedProductEditor title="Popular Products" id="popular" images={popularImages} onChange={(images: ImageRecord[]) => setPopularImages(images)} />
                        <RelatedProductEditor title="New Products" id="newItems" images={newItemsImages} onChange={(images: ImageRecord[]) => setNewItemsImages(images)} />
                    </MasterContainer>
                </React.Fragment>
            }
        </MasterPage>
    )
}
export default Home;